@import '../variables.module';

.infoPaper {
  padding: 20px;
  color: $primaryColor;
  [class~='MuiTypography-overline'] {
    line-height: initial;
    color: $primaryDarkColor;
  }
}
.infoContainer {
  border-radius: $borderRadius;
  background-color: $lightergray;
  padding: $borderRadius;
  margin-top: calc(2 * $borderRadius);
  [class~='MuiTypography-caption'] {
    line-height: initial;
    color: $lightygray;
  }
  [class~='MuiTypography-h6'] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: initial;
    color: $primaryDarkColor;
    margin-top: calc($borderRadius / 2);
    margin-bottom: $borderRadius;
    font-weight: 500;
    a {
      overflow: auto;
      text-overflow: ellipsis;
      font-weight: 400;
      color: $primaryDarkColor;
    }
  }
  [class~='MuiTypography-subtitle1'] {
    color: $primaryDarkColor;
  }
  .linkContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    margin-top: 7px;
    [class~=MuiTypography-h6] {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .copyIcon {
      background-color: $secondaryColor;
      border-radius: $borderRadius;
      color: $simplewhite;
    }
    .QRIcon {
      background-color: $secondaryDarkColor;
      border-radius: $borderRadius;
      color: $simplewhite;
      width: 40px;
    }
  }
}
.paperHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: $breakpointMedium) {
    flex-wrap: nowrap;
  }
  .paperHeaderAvatar {
    height: 60px;
    width: 60px;
    margin-right: 10px;
  }
  .paperHeaderContent {
    margin: 0px;
    flex: auto;
    [class~='MuiTypography-root'] {
      color: $primaryDarkerColor;
    }
  }
  .paperHeaderSimple {
    margin: 0px;
    [class~='MuiTypography-root'] {
      color: $secondaryColor;
      font-weight: 700;
    }
    order: 0;
  }
  .paperHeaderQuery {
    order: 1;
    @media only screen and (max-width: $breakpointMedium) {
      order: 2;
      width: 100%;
      margin-top: 10px;
    }
  }
  .paperHeaderActions {
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
    order: 2;
    @media only screen and (max-width: $breakpointMedium) {
      order: 1;
      flex-direction: column;
    }
    .baseAction {
      @media only screen and (max-width: $breakpointMedium) {
        [class~=MuiTypography-root] {
          display: none;
        }
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon {
        margin-bottom: 5px;
        color: $simplewhite;
        border-radius: 50%;
      }
    }
    .editAction{
      color: $primaryColor;
      @media only screen and (min-width: $breakpointMedium) {
        margin-right: $borderRadius;
      }
      .editIcon {
        background-color: $primaryColor;
      }
    }
    .deleteAction {
      color: $errorColor;
      .deleteIcon {
        background-color: $errorColor;
      }
    }
  }
}
.modal {
  max-height: 80vh;
  margin-top: 10vh;
  padding: $borderRadius;
  overflow-y: auto;
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $borderRadius;
    padding-bottom: 20px;
    [class~='MuiTypography-root'] {
      color: $primaryDarkColor;
      font-weight: bold;
    }
  }
  .modalTable {
    overflow-y: auto;
    max-height: 40vh;
  }
}

.noWrap {
  white-space: nowrap;
  @media only screen and (max-width: $breakpointMedium) {
    white-space: unset;
  }
}