@use 'sass:color';
@import '../../../../common/styles/variables.module';

.container {
  background-color: #fafbff;
  border: 1px solid #f5f6ff;
  border-radius: 10px;
  width: 100%;
  padding: 31px 19px 22px 18px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  border-radius: 10px;

  [class~='MuiSkeleton-root'] {
    height: calc(94px + 6px);
    border-radius: 10px;
  }
}

.productContainer {
  padding: 6px 5px;
  background-color: #f5f6ff;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 10px;
  justify-content: space-between;
  cursor: pointer;

  img {
    width: 94px;
    height: 94px;
    border-radius: 10px;
  }
  @media only screen and (max-width: $breakpointMedium) {
    flex-direction: column-reverse;
    gap: 0px;
  }
}

.mainContainer {
  display: flex;
  gap: 58px;
  width: 100%;
  @media only screen and (max-width: $breakpointMedium) {
    flex-direction: column;
    gap: 14px;
    padding: 0px 20px 22px 20px;
    width: unset;
  }
}

.selectorPriceContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 58px;
  @media only screen and (max-width: $breakpointMedium) {
    gap: 72px;
    justify-content: space-between;
  }
  @media only screen and (max-width: $breakpointSmall) {
    gap: 22px;
    justify-content: space-between;
  }
}

.imageNameContainer {
  display: flex;
  gap: 20px;
  width: 100%;
  @media only screen and (max-width: $breakpointSmall) {
    gap: 22px;
  }
}

.imageContainer {
  width: 100%;
}

.titleContainer {
  max-width: 173px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  [class~='MuiTypography-h5'] {
    font-size: 16px;
    line-height: 130%;
  }
  @media only screen and (max-width: $breakpointMedium) {
    max-width: 100%;
  }
}

.selectorContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 109px;

  .leftButton {
    border: 1px solid #d5d8ec;
    border-right: 0px;
    padding: 5px 25px 5px 9px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    height: 18px;
    svg {
      color: #9ea2be;
    }
    :hover {
      cursor: pointer;
    }
  }

  .rightButton {
    border: 1px solid #d5d8ec;
    border-left: 0px;
    padding: 5px 9px 5px 25px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    height: 18px;
    svg {
      color: #9ea2be;
    }
    :hover {
      cursor: pointer;
    }
  }

  .quantity {
    border: 1px solid #d5d8ec;
    border-left: 0px;
    border-right: 0px;
    height: 28px;
  }
}

.price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 84px;
  .discount {
    white-space: nowrap;
    .strikethrough {
      text-decoration: line-through;
    }
  }

  [class~='MuiTypography-h5'] {
    font-weight: 600;
    font-size: 20px;
  }
}

.xMark {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d5d8ec;
  border-radius: 50%;
  min-width: 17px;
  height: 17px;
  background-color: white;
  margin-left: 15px;

  svg {
    color: #9ea2be;
    width: 10px;
    height: 10px;
  }

  :hover {
    cursor: pointer;
  }
  @media only screen and (max-width: $breakpointMedium) {
    align-self: flex-end;
  }
}

.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .subtotalContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    .subtotalRow {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 100px);
      padding: 10px 50px;
    }
  }

  .deliveryContainer {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 100px);
    padding: 10px 50px;
    margin-bottom: 17px;
    [class~='MuiTypography-root'] {
      font-size: 14px;
    }
  }

  .totalContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    width: calc(100% - 100px);
    padding: 10px 50px;
    margin-bottom: 17px;
    [class~='MuiTypography-root'] {
      font-size: 22px;
      font-weight: 600;
    }
    [class~='MuiTypography-subtitle1'] {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .recurrenceContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 50px;
    gap: 14px;

    .selectors {
      width: 100%;
      [class~='MuiOutlinedInput-input'] {
        padding: 8.5px 14px !important;
        background: white;
        border-radius: 10px;
      }
      [class~='MuiOutlinedInput-root'][class~='Mui-focused'] {
        fieldset {
          border: 2px solid var(--dynamic-border-color);
        }
      }
      [class~='MuiOutlinedInput-root']:hover {
        [class~='MuiOutlinedInput-notchedOutline'] {
          border-color: var(--dynamic-border-color);
        }
      }
    }
  }
}

.paymentProductContainer {
  padding: 6px 5px;
  background-color: #f5f6ff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  gap: 15px;

  img {
    width: 57px;
    height: 57px;
    border-radius: 10px;
  }

  @media only screen and (max-width: 1330px) {
    width: calc(100% - 10px);
    gap: 0px;
    margin-bottom: 5px;
  }
}

.productName {
  [class~='MuiTypography-h5'] {
    font-size: 16px;
    line-height: 130%;
    font-weight: 700;
  }
  [class~='MuiTypography-subtitle1'] {
    font-size: 13px;
    line-height: 130%;
    font-weight: 700;
  }
}

.productQuantityContainer {
  background-color: white;
  border: 1px solid #d5d8ec;
  border-radius: 10px;
  max-width: fit-content;
  padding: 0px 9px;
  [class~='MuiTypography-subtitle1'] {
    font-weight: 400;
  }
}

.priceWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  [class~='MuiTypography-h6'] {
    font-size: 14px;
    font-weight: 400;
  }
  [class~='MuiTypography-h5'] {
    font-size: 18px;
  }
}

.paymentTotal {
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
}

.paymentDeliveryContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  [class~='MuiTypography-root'] {
    font-size: 12px;
  }
}
.extraAnnotation {
  font-size: 0.8em;
  display: flex;
  justify-content: flex-start;
  padding-top: 0px;
  padding-right: 15%;
  margin-bottom: 1em;
  width: 100%;
  [class~='MuiTypography-root'] {
    font-size: 0.8em;
  }
}

.paymentSummaryContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  [class~='MuiAccordion-root'] {
    border-radius: 10px;
  }
  [class~='MuiAccordion-root']::before {
    height: 0px;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
  }
  .amountSummary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    width: calc(100% - 40px);
    border: 1px solid #9ea2be;
    background-color: #f5f6ff;
    border-radius: 10px;
    margin-top: 10px;
    .quantity {
      min-width: 60%;
      [class~='MuiTypography-subtitle2'] {
        font-size: 12px;
      }
      [class~='MuiTypography-h4'] {
        font-size: 20px;
        font-weight: 600;
      }
    }
    .total {
      width: 40%;
      [class~='MuiTypography-subtitle2'] {
        font-size: 12px;
      }
      [class~='MuiTypography-h4'] {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .accordion {
    [class~='MuiAccordionDetails-root'] {
      padding: 0 8px 7px;
    }
  }
}
