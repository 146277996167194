@use 'sass:color';
@import '../../../common/styles/variables.module';

.backgroundPaper {
  background-color: white;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.formContainer {
  width: 100%;
  padding-right: 25px;
}

.infoPaper {
  width: 100%;
  padding: 20px;

  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 20px;
    padding: 10px;
  }
}

.paperHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2%;

  .paperHeaderAvatar {
    height: 60px;
    width: 60px;
    margin-right: 10px;
  }

  .paperHeaderContent {
    display: flex;
    flex-direction: row;

    [class~='MuiTypography-h5'] {
      color: white;
      font-weight: 700;
    }
    [class~='MuiTypography-h6'] {
      font-weight: 500;
    }
    [class~='MuiTypography-body1'] {
      color: white;
      font-weight: 400;
    }

    @media only screen and (max-width: $breakpointMedium) {
      [class~='MuiTypography-h5'] {
        font-size: 20px;
      }
      [class~='MuiTypography-h6'] {
        display: none;
      }
      [class~='MuiTypography-body1'] {
        font-size: 14px;
      }
    }
  }
}

.gridItemMargin {
  margin-top: -80px;

  [class~='MuiTypography-h6'] {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #0d3b56;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: $breakpointMedium) {
    margin-top: -40px;
    [class~='MuiInputBase-input'] {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    [class~='MuiGrid-root'] {
      padding-top: 5px;
    }
    [class~='MuiOutlinedInput-root'] {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
}

.informationContainer {
  padding-top: 22px;
  padding-left: 22px;

  .addProductButton {
    [class~='MuiTypography-root'] {
      text-decoration: underline;
      margin-top: 10px;
    }
  }

  .containerHeader {
    margin-bottom: 10px;

    [class~='MuiTypography-h6'] {
      font-weight: 700;
      line-height: 20px;
    }
    [class~='MuiTypography-subtitle1'] {
      color: $lightGray;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    padding: 0;
  }
}

.productContainer {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;

  [class~='MuiAutocomplete-root'] {
    width: 70%;
  }
  .productQuantity {
    display: flex;
    align-items: center;
    border: 1px solid $slightyLightyGray;
    border-radius: 10px;
    max-height: 39px;
    padding: 0 2px;

    [class~='MuiButton-root'] {
      max-width: 35px;
      min-width: 35px;
      min-height: 35px;
      max-height: 35px;
      border-radius: 10px;
      border-width: 0px;
      background-color: $slightyLightyGray;
      color: $lightGray;
      font-weight: 700;
      [class~='MuiTypography-root'] {
        margin: 0px;
        font-size: x-large;
      }
    }
    [class~='MuiTypography-root'] {
      margin: 20px;
    }
  }
  .icon {
    margin-bottom: 5px;
    color: $simplewhite;
    border-radius: 50%;
    background-color: $errorColor;
  }
  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
    [class~='MuiAutocomplete-root'] {
      min-width: 100%;
    }
    flex-wrap: wrap;
    justify-content: flex-end;
    .productQuantity {
      margin-right: 10px;
      max-height: 30px;
      [class~='MuiButton-root'] {
        max-width: 25px;
        min-width: 25px;
        min-height: 25px;
        max-height: 25px;
        border-radius: 8px;
        border-width: 0px;
        background-color: $slightyLightyGray;
        color: $lightGray;
        font-weight: 700;
        [class~='MuiTypography-root'] {
          margin: 0px;
          font-size: large;
        }
      }
      [class~='MuiTypography-root'] {
        margin: 10px;
      }
    }
    .icon {
      margin-top: 5px;
      height: 30px;
      width: 30px;
      border-radius: 5px;
    }
  }
}

.priceContainer {
  display: flex;
  align-items: flex-end;
  .currency {
    width: 25%;
    margin-right: 10px;
  }
  .amount {
    width: calc(100% - 10px - 25%);
  }
}

.fieldContainer {
  width: 50%;
  margin-top: 20px;
  .fieldHeader {
    margin-bottom: 5px;
  }
  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
    margin-top: 10px;
  }
}

.recurrenceContainer {
  width: 100%;
  margin-top: 20px;
  [class~='MuiTextField-root'] {
    width: 50%;
    margin-right: 20px;
    @media only screen and (max-width: $breakpointMedium) {
      width: 100%;
      margin-right: 0;
    }
  }
  .fieldHeader {
    margin-bottom: 5px;
  }
  .recurrenceInput {
    display: flex;

    .recurrenceGrid {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.multiInputContainer {
  display: flex;
  width: 100%;
  .option {
    width: 58%;
    margin-right: 2%;
    [class~='MuiInputBase-input'] {
      font-size: 14px;
    }
  }
  .input {
    width: 50%;
  }
}

.extraFieldsContainer {
  display: flex;
  flex-direction: column;
  [class~='MuiTextField-root'] {
    width: 30%;
    margin-right: 20px;
    [class~='MuiFormHelperText-root'] {
      text-align: left;
      margin-left: 0px;
    }
  }

  .adornment {
    svg {
      width: 20px;
      height: 20px;
      color: $errorColor;
    }
    :hover {
      cursor: pointer;
    }
  }

  .addExtraFieldButton {
    width: fit-content;
    [class~='MuiTypography-root'] {
      text-decoration: underline;
      margin-top: 10px;
      margin-right: 15px;
      margin-left: 10px;
    }
  }

  .defaultExtraField {
    margin-bottom: 20px;
    [class~='MuiTypography-subtitle1'] {
      line-height: normal;
    }
    [class~='MuiTypography-body1'] {
      font-size: 12px;
      font-style: italic;
      line-height: normal;
      color: $lightGray;
    }
  }

  .extraField {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @media only screen and (max-width: $breakpointMedium) {
      width: 100%;
      margin-bottom: 10px;
      [class~='MuiTextField-root'] {
        min-width: 100%;
        margin: 0;
      }
      flex-wrap: wrap;
    }
  }
}

.submitForm {
  width: 100%;
  display: flex;
  justify-content: center;
}

.singlePaymentContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  .description {
    width: 50%;
  }
  .image {
    width: 50%;
  }
  @media only screen and (max-width: $breakpointMedium) {
    flex-direction: column;
    .description {
      width: 100%;
    }
    .image {
      width: 100%;
    }
  }
}

.gridProductImage {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  box-shadow: 0px 1.32813px 9.29688px 0px rgba(0, 0, 0, 0.12);
  padding: 30px 0px;
  border-radius: 11px;

  @media only screen and (max-width: $breakpointMedium) {
    justify-content: center;
    max-width: unset;
    margin: 20px 0;
  }

  .photoTitle {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 24px;
    margin: 0px 20px 24px 25px;
  }

  .photoSubtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 24px 20px 0px 25px;
    margin-top: 24px;
    color: $lightGray;
    text-align: center;
  }

  .paperProductImage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    border-radius: 50%;

    .containerProductImage {
      width: 95%;
      height: 95%;
      border-radius: 50%;
      background-color: #30a3ab;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding: 0 !important;

      .buttonProductImage {
        z-index: 1;
        min-width: 0;
        border-radius: 50%;
        height: fit-content;
        width: fit-content;
        background: rgba(88, 177, 194, 0.7);
        position: relative;
        top: 20px;
      }

      .logoProduct {
        position: relative;
        z-index: 0;
        height: 100%;
        width: 100%;
        top: 30px;
        border-radius: 50%;
      }
    }
  }
}

.defaultEfsContainer {
  margin-bottom: 10px;
  [class~='MuiTypography-h6'] {
    font-size: 17px;
    font-weight: 600;
  }
  [class~='MuiTypography-body1'] {
    color: $lightGray;
  }
  .defaultFieldRow {
    display: flex;
    flex-direction: row;
    [class~='MuiFormControlLabel-root'] {
      margin: 0;
    }
    [class~='MuiTypography-root'] {
      color: #000000de;
      white-space: nowrap;
    }
    .shippingExplain {
      display: flex;
      align-items: center;
      p {
        margin: 0;
      }
      [class~='MuiTypography-root'] {
        color: #000000de;
        font-size: 14px;
        white-space: unset;
      }
    }
  }
}

.selector {
  width: 15% !important;
  margin-right: 2%;
  [class~='MuiInputBase-input'] {
    font-size: 14px;
  }
}

.drawerContainer {
  width: 33vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  .drawerField {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    [class~='MuiTextField-root'] {
      width: 100%;
    }
  }
  .actions {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
  [class~='MuiIconButton-root'] {
    color: $primaryColor;
  }

  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 60px;
    width: 100vw;
  }
}

.inputField {
  width: 100%;
}

.optionsInput {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.invoiceConfigContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.required {
  font-weight: 700;
  color: $errorColor;
}
