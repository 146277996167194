@import '../variables.module';

.mainDiv {
  [class~=mainBox] {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $simplewhite;
    margin-top: $borderRadius;
    border-radius: $borderRadius;
    
    [class~=infoContainer] {
      padding: $borderRadius 0 $borderRadius $borderRadius;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      [class~=content] {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
        [class~=title] {
          margin: 0 5px;
          color: $primaryDarkerColor;
        }
        [class~=ellipsis] {
          margin: 0 5px;
          color: $primaryDarkerColor
        }
      }
    }
    [class~=moreInfo] {
      min-height: 100%;
      width: 35px;
      margin-left: 5px;
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $simplewhite;
      background-color: $yellowColor;
    }
  }
  [class~=collapse] {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $simplewhite;
    margin-top: $borderRadius;
    border-radius: $borderRadius;
    display: none;
    &[class~=openCollapse] {
      display: block;
    }
  }
  [class~=emptyBox] {
    width: 100%;
    background-color: $simplewhite;
    border-radius: $borderRadius;
    margin-top: $borderRadius;
    color: $primaryDarkerColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $borderRadius 0 $borderRadius 0;
  }
}
