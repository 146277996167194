@use 'sass:color';
@import '../../../common/styles/variables.module';

.backgroundPaper {
  background-color: white;
  padding: 16px;
  margin-bottom: 16px;
  @media only screen and (max-width: $breakpointMedium) {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
}

.mainGrid {
  width: 100%;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: $breakpointMedium) {
    flex-direction: column;
    height: fit-content;
  }
}

.formContainer {
  width: 100%;
}

.infoPaper {
  padding: 20px;
  width: 100%;

  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 20px;
    padding: 40px 20px;
  }
}

.paperHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2%;

  .paperHeaderAvatar {
    height: 60px;
    width: 60px;
    margin-right: 10px;
  }

  .paperHeaderContent {
    display: flex;
    flex-direction: row;

    [class~='MuiTypography-h5'] {
      color: white;
      font-weight: 700;
    }
    [class~='MuiTypography-h6'] {
      font-weight: 500;
    }
    [class~='MuiTypography-body1'] {
      color: white;
      font-weight: 400;
    }

    @media only screen and (max-width: $breakpointMedium) {
      [class~='MuiTypography-h5'] {
        font-size: 20px;
      }
      [class~='MuiTypography-h6'] {
        display: none;
      }
      [class~='MuiTypography-body1'] {
        font-size: 14px;
      }
    }
  }
}

.gridProductImage {
  max-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  box-shadow: 0px 1.32813px 9.29688px 0px rgba(0, 0, 0, 0.12);
  padding: 30px 0px;
  border-radius: 11px;

  @media only screen and (max-width: $breakpointMedium) {
    justify-content: center;
    max-width: unset;
    margin: 20px 0;
  }

  .photoTitle {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 24px;
    margin: 0px 20px 24px 25px;
  }

  .photoSubtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 24px 20px 0px 25px;
    margin-top: 24px;
    color: $lightGray;
    text-align: center;
  }

  .paperProductImage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    border-radius: 50%;

    .containerProductImage {
      width: 95%;
      height: 95%;
      border-radius: 50%;
      background-color: #30a3ab;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding: 0 !important;

      .buttonProductImage {
        z-index: 1;
        min-width: 0;
        border-radius: 50%;
        height: fit-content;
        width: fit-content;
        background: rgba(88, 177, 194, 0.7);
        position: relative;
        top: 20px;
      }

      .logoProduct {
        position: relative;
        z-index: 0;
        height: 100%;
        width: 100%;
        top: 30px;
        border-radius: 50%;
      }
    }
  }
}

.gridItemMargin {
  padding-left: 20px;

  [class~='MuiTypography-h6'] {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #0d3b56;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 0;
    padding: 0;
    min-width: 100%;
    [class~='MuiInputBase-input'] {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    [class~='MuiGrid-root'] {
      padding-top: 5px;
    }
    [class~='MuiOutlinedInput-root'] {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
}

.fieldHeader {
  margin-bottom: 5px;
  white-space: nowrap;
}

.dialogContainer {
  [class~='MuiDialogActions-root'] {
    display: flex;
    flex-direction: column;
  }
}

.required {
  color: $errorColor;
  font-weight: 700;
}
