@use 'sass:color';
@import '../../../common/styles/variables.module';
@import '../../../common/styles/common.module.scss';

.infoBoxContainer {
  display: flex;
  flex-direction: row;

  .firstInfoContainer {
    @extend .baseInfoContainer;
    margin-bottom: 10px !important;
    margin-right: 10px;
    [class~='MuiTypography-root'] {
      color: $warningColor;
    }
    [class~='titleIcon1'] {
      color: $warningColor;
      svg {
        width: 1.5em;
      }
    }
    [class~='footer'] {
      background-color: $warningColor;
    }
  }

  .secondInfoContainer {
    @extend .baseInfoContainer;
    margin-bottom: 10px !important;
    margin-right: 10px;
    [class~='MuiTypography-root'] {
      color: $successColor;
    }
    [class~='titleIcon1'] {
      color: $successColor;
      svg {
        width: 1.5em;
      }
    }
    [class~='footer'] {
      background-color: $successColor;
    }
  }

  .thirdInfoContainer {
    @extend .baseInfoContainer;
    margin-bottom: 10px !important;
    margin-right: 10px;
    [class~='MuiTypography-root'] {
      color: $pendingColor;
    }
    [class~='titleIcon1'] {
      color: $pendingColor;
      svg {
        width: 1.5em;
      }
    }
    [class~='footer'] {
      background-color: $pendingColor;
    }
  }
}

.whiteBackground {
  background: $simplewhite;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  max-width: 230px;
  text-align: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}
