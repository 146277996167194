@use 'sass:color';
@import '../../styles/variables.module';

$gradient: linear-gradient(
  180deg,
  color.adjust($secondaryColor, $alpha: -0.85) 0%,
  $secondaryColor 80%
);
$gradientMobile: linear-gradient(
  180deg,
  color.adjust($secondaryColor, $alpha: -0.75) 0%,
  $secondaryColor 120%
);

.dashBody {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: calc(100vh - 120px - $borderRadius);
  padding: 20px;
  margin: 10px 10px 0px auto;
  background-color: $primaryLightestColor;
  background: $gradient;
  overflow-x: hidden;
  overflow-y: auto;

  transition: width $animTime ease-out;

  @media only screen and (max-width: $breakpointMedium) {
    margin: 0;
    background: $gradientMobile;
    height: calc(100vh - 96px);
    border-radius: 0;
  }
}

.generalContainer {
  background-color: $simplewhite;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .textContainer {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  img {
    max-height: 330px;
    max-width: 390px;
    min-width: 250px;
    min-height: 200px;
    @media only screen and (max-width: $breakpointMedium) {
      max-width: 250px;
    }
  }
  .title {
    color: #cb1d1d;
    font-size: 42px;
    font-weight: 900;
    @media only screen and (max-width: $breakpointMedium) {
      max-width: 250px;
    }
  }
  .text {
    font-size: 26px;
  }
  .onlypaysLogo {
    margin-top: 20px;
    max-width: 200px;
    min-width: unset;
    min-height: unset;
  }
  [class~='MuiButton-root'] {
    margin: 10px;
  }
}
