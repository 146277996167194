.priceFooter {
  margin-top: 20px;

  .dispatch {
    display: flex;
    align-items: center;
  }

  .cursive {
    font-style: italic;
  }

  .summary {
    margin-top: 16px;

    [class~='MuiTypography-h3'] {
      font-size: 22px;
      margin-bottom: 5px;
    }

    [class~='MuiTypography-subtitle1'] {
      font-size: 16px;
      margin-bottom: 25px;
    }

    [class~='MuiOutlinedInput-input'] {
      padding: 8.5px 14px !important;
    }

    [class~='MuiOutlinedInput-root'][class~='Mui-focused'] {
      fieldset {
        border: 2px solid var(--dynamic-border-color);
      }
    }
    [class~='MuiOutlinedInput-root']:hover {
      [class~='MuiOutlinedInput-notchedOutline'] {
        border-color: var(--dynamic-border-color);
      }
    }

    .recurrenceSelector {
      margin-top: 25px;
      width: 100%;
      margin-bottom: 25px;
    }

    .amountSummary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      margin: auto;
      margin-top: 10px;
      max-width: 402px;
      border: 1px solid #9ea2be;
      background-color: #f5f6ff;
      border-radius: 10px;

      .quantity {
        min-width: 50%;
        [class~='MuiTypography-subtitle2'] {
          font-size: 12px;
        }
        [class~='MuiTypography-h4'] {
          font-size: 20px;
          font-weight: 600;
        }
      }

      .total {
        width: 50%;
        [class~='MuiTypography-subtitle2'] {
          font-size: 12px;
        }
        [class~='MuiTypography-h4'] {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }
}
