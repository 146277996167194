@import '../../../../common/styles/variables.module';

.titleForm {
  text-align: center;
  margin-bottom: 30px;
  @media only screen and (max-width: $breakpointMedium) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 8px;
  color: $primaryBlue;
  @media only screen and (max-width: $breakpointMedium) {
    width: 90%;
  }
}

.stepDescription {
  font-size: 16px;
  @media only screen and (max-width: $breakpointMedium) {
    text-align: center;
  }
}

.mediumText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.infoAlert {
  margin-bottom: 10px;
}

.mandatoryText {
  color: #dd5151;
}

.buyerData {
  width: 360px;
  [class~='MuiOutlinedInput-root'][class~='Mui-focused'] {
    fieldset {
      border: 2px solid var(--dynamic-border-color);
    }
  }
  [class~='MuiOutlinedInput-root']:hover {
    [class~='MuiOutlinedInput-notchedOutline'] {
      border-color: var(--dynamic-border-color);
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
  }
}

.discountRow {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  [class~='Typography-root'] {
    white-space: nowrap;
    width: 30%;
  }
  [class~='MuiFormControl-root'] {
    width: 70% !important;
  }
}
