@import '../../common/styles/variables.module';

.tablePagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.table {
  .tableHeader {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;

    .title {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      order: 0;
      color: $primaryDarkerColor;

      [class~='MuiTypography-root'] {
        font-weight: bold;
      }

      [class~='MuiTypography-body1'] {
        color: $primaryLightestColor;
        text-decoration: underline;
        font-weight: normal;
        a:visited {
          color: $primaryLightestColor;
        }
      }
    }

    .query {
      order: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      .queryBox {
        margin-right: 5px;
        [class~='MuiInputAdornment-root'] {
          color: $primaryLightColor;
        }
        [class~='MuiOutlinedInput-root'] {
          & fieldset {
            border-color: $primaryLightColor;
          }
          border: $primaryLightColor;
          border-radius: $borderRadius;
          border-color: $primaryLightColor;
          border-width: $borderWidth;
        }
      }
    }

    .actions {
      order: 2;
      border-radius: $borderRadius;
      box-shadow: 0px 0px 4px;
      color: $primaryLightColor;
    }
  }

  .headRow {
    background-color: $lightygray;
    color: $primaryDarkerColor;
    border-radius: $borderRadius;
    padding-right: calc($actionsWidth + $borderRadius);
  }

  .tableRow {
    background-color: $lightergray;
    color: $primaryDarkColor;
    border-radius: $borderRadius;

    .tableAction {
      width: $actionsWidth;
      box-shadow: 0px 1px 3px $lightGray;
    }

    .tableAction:hover {
      background-color: $primaryDarkColor;
      color: $simplewhite;
    }
  }
}

.paperHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: $breakpointMedium) {
    flex-wrap: wrap;
  }

  .paperHeaderAvatar {
    height: 60px;
    width: 60px;
    margin-right: 10px;
  }

  .paperHeaderContent {
    margin: 0px;
    flex: auto;
    [class~='MuiTypography-root'] {
      color: $primaryDarkColor;
      font-weight: 700;
    }
    [class~='MuiTypography-h6'] {
      font-weight: 500;
    }

    @media only screen and (max-width: $breakpointMedium) {
      [class~='MuiTypography-h5'] {
        font-size: 1.2rem;
      }
      [class~='MuiTypography-h6'] {
        display: none;
      }
    }
  }

  .paperHeaderSimple {
    margin: 0px;
    [class~='MuiTypography-root'] {
      color: $secondaryColor;
      font-weight: 700;
    }
    order: 0;
  }

  .paperHeaderQuery {
    order: 1;
    @media only screen and (max-width: $breakpointMedium) {
      order: 2;
      width: 100%;
      margin-top: 10px;
    }
  }

  .paperHeaderActions {
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
    order: 2;
    @media only screen and (max-width: $breakpointMedium) {
      order: 1;
    }
  }
}

.mobileDisplay {
  .mobileHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    color: $primaryDarkerColor;
    [class~='MuiIconButton-root'] {
      border-radius: 10px;
    }
    .downloadButton {
      border: 1px $primaryDarkerColor solid;
      color: $primaryDarkerColor;
      margin-right: 7px;
    }
    .filterButton {
      border: 1px $primaryDarkerColor solid;
      color: $primaryDarkerColor;
    }
  }

  .query {
    order: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    .queryBox {
      margin-right: 5px;
      [class~='MuiInputAdornment-root'] {
        color: $primaryLightColor;
      }
      [class~='MuiOutlinedInput-root'] {
        & fieldset {
          border-color: $primaryLightColor;
        }
        border: $primaryLightColor;
        border-radius: $borderRadius;
        border-color: $primaryLightColor;
        border-width: $borderWidth;
      }
    }
  }
}

.mobileListPagination {
  width: 100%;
  margin-top: $borderRadius;
}
