@import '../../common/styles/variables.module';

[class~='MuiTableContainer-root'] {
  padding-right: 10px;
}

[class~='MuiMenu-paper'] {
  box-shadow: 0px 1px 6px rgba(158, 158, 158, 0.25);
}

.table {
  &[class~='MuiTable-root'] {
    border-collapse: separate;
    border-spacing: 0 calc($borderRadius / 2);
  }
  &[class~='MuiTableRow-root'] {
    transition: heigth $animTime ease-out;
  }
  &[class~='MuiTableCell-root'] {
    padding: $borderRadius;
  }
  [class~='MuiTableHead-root'] [class~='MuiTableCell-root'] {
    color: $primaryDarkerColor;
    font-weight: bold;
    background-color: $tableHeaderColor;
    font-size: 14px;
    border: none;
    &:first-child {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }
    &:last-child {
      border-bottom-right-radius: $borderRadius;
      border-top-right-radius: $borderRadius;
    }
  }
  [class~='MuiTableBody-root'] [class~='MuiTableRow-root'] [class~='MuiTableCell-root'] {
    background-color: $lightergray;
    color: #0d3a56;
    border: none;
    max-height: 34px;
    &:first-child {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }
  }
  &selected {
    background-color: #0d3a56;
    color: $lightergray;
  }
}

.actionedTable {
  [class~='MuiTableHead-root'] [class~='MuiTableCell-root'] {
    color: $primaryDarkerColor;
    font-weight: bold;
    background-color: $tableHeaderColor;
    font-size: 14px;
    border: none;
    &:first-child {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }
    &:last-child {
      border-bottom-right-radius: $borderRadius;
      border-top-right-radius: $borderRadius;
    }
  }
  [class~='MuiTableBody-root'] [class~='MuiTableRow-root'] [class~='MuiTableCell-root'] {
    &:nth-last-child(3) {
      border-bottom-right-radius: $borderRadius;
      border-top-right-radius: $borderRadius;
    }
    &:nth-last-child(2) {
      background-color: $simplewhite;
      padding: 5px;
    }
    &:last-child {
      text-align: center;
      margin: $borderRadius;
      border-radius: $borderRadius;
      background-color: $simplewhite;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
      padding-left: 27px;
      padding-right: 27px;
    }
    &:last-child:hover {
      cursor: pointer;
    }
    [class~='MuiButton-text'] {
      text-decoration: underline;
    }
  }
}

.notActionedTable {
  [class~='MuiTableBody-root'] [class~='MuiTableRow-root'] [class~='MuiTableCell-root'] {
    &:first-child {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }
    &:last-child {
      border-bottom-right-radius: $borderRadius;
      border-top-right-radius: $borderRadius;
    }
  }
}

.title {
  text-align: left;
}

.collapsedTable {
  margin: 5px;
  [class~='MuiTableCell-root'] {
    text-align: center;
  }
  [class~='MuiTable-root']:hover {
    cursor: default;
  }
  [class~='MuiTableHead-root'] [class~='MuiTableRow-root'] [class~='MuiTableCell-root'] {
    background-color: $simplewhite;
    &:last-child {
      box-shadow: none;
    }
    &:last-child:hover {
      cursor: default;
    }
  }
  [class~='MuiTableBody-root'] [class~='MuiTableRow-root'] [class~='MuiTableCell-root'] {
    background-color: $simplewhite;
    &:nth-last-child(3) {
      border-bottom-right-radius: $borderRadius;
      border-top-right-radius: $borderRadius;
    }
    &:last-child {
      box-shadow: none;
    }
    &:last-child:hover {
      cursor: pointer;
    }
    [class~='MuiButton-text'] {
      text-decoration: underline;
    }
  }
}

.selected {
  background-color: #0d3a56;
  color: $lightergray;
}

.chipsButton {
  background-color: $primaryDarkerColor;
  color: $simplewhite;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s;
}

.chipsButton:hover {
  cursor: pointer;
  background-color: $primaryColor;
}

.collapseAction {
  :hover {
    cursor: pointer;
  }
  u {
    white-space: nowrap;
  }
}
