@import '../../common/styles/variables.module';

.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  .regionsContainer {
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
    .defaultFieldRow {
      flex: 0 0 25%;
      box-sizing: border-box;
    }
  }
  .communesContainer {
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
    .defaultFieldRow {
      flex: 0 0 25%;
      box-sizing: border-box;
    }
  }
  .communeRow {
    margin-left: 20px;
  }
}
