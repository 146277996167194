@import '../../common/styles/variables.module';

.appBar {
  z-index: 1201;
  background-color: transparent;
  color: $primaryColor;
  padding: 0;
  transition: all $animTime ease-out;

  .userControl {
    height: 48px;
    background: linear-gradient(90deg, $primaryColor -50%, $secondaryDarkerColor 150%);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
    [class~='MuiAvatar-root'] {
      height: 38px;
      width: 38px;
    }
    [class~='MuiButtonBase-root'] {
      color: $simplewhite;
    }
    @media only screen and (max-width: $breakpointMedium) {
      background: none;
      [class~='MuiButtonBase-root'] {
        display: none;
      }
    }
  }

  .topBar {
    background-color: $primaryDarkerColor;
    justify-content: flex-end;
    margin: $borderRadius $borderRadius 0 0;
    border-radius: $borderRadius;
    
    @media only screen and (max-width: $breakpointMedium) {
      position: fixed;
      background-color: $primaryDarkerColor;
      width: calc(100vw - 33px);
      margin: 0;
      z-index: 1202;
      border-radius: 0px;
    }
  }

  .hamburguerButton {
    border-radius: $borderRadius;
    background-color: $simplewhite;
    padding: 4px;
    svg {
      height: 25px;
      width: 25px;
      color: $primaryDarkerColor;
    }
    &:hover {
      background-color: $primaryLightestColor;
      svg {
        color: $simplewhite;
      }
    }
    
    @media only screen and (min-width: $breakpointMedium) {
      display: none;
    }

    &.mobileDrawerOpen {
      display: none;
    }
  }

  &.drawerClosed {
    @media only screen and (min-width: $breakpointMedium) {
      margin-left: calc($sidebarClosedWidth + 16px);
    }
    width: calc(100vw - $sidebarClosedWidth - 16px);
  }

  &.drawerOpen {
    margin-left: $sidebarWidth;
    width: calc(100vw - $sidebarWidth);
  }

  &.isMobile {
    width: 100%;
  }
}

.profileMenu {
  [class~='MuiPopover-paper'] {
    [class~='MuiList-root'] {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: $sidebarWidth;
      padding-top: 0;

      .menuGrid {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $primaryDarkColor;
        height: 100%;
        padding: $borderRadius $borderRadius 0 $borderRadius;
        margin-bottom: 12px;
      }

      [class~='MuiAvatar-circular'] {
        width: $imageSize;
        height: $imageSize;
        margin: 8px;
      }

      [class~='MuiTypography-h6'] {
        color: $simplewhite;
        font-weight: bold;
      }
      
      [class~='MuiTypography-subtitle2'] {
        color: $simplewhite;
        margin-bottom: 15px;
      }

      [class~='MuiMenuItem-root'] {
        width: 90%;
        border-radius: $borderRadius;
        margin-bottom: 3px;
        padding: 12px;
        font-size: 16px;
        line-height: 19px;
        color: $greyDarkColor;
        svg {
          height: 18px;
          width: auto;
          margin-right: 15px;
        }
      }

      @media only screen and (max-width: $breakpointMedium) {
        width: 80vw;
      }
    }
  }
}
