@use 'sass:color';
@import '../../../common/styles/variables.module.scss';
@import '../../../common/styles/common.module.scss';

.mainContainer {
  height: 100%;
}

.tabsBox {
  margin-bottom: 10px;
  [class~='MuiTab-root'] {
    color: $simplewhite;
    font-weight: 300;
    font-size: 14px;
  }
  [class~='Mui-selected'] {
    color: $simplewhite;
    font-weight: 700;
    font-size: 16px;
  }
  [class~='MuiTabs-indicator'] {
    background: $simplewhite;
  }
}

.boxsContainer {
  display: flex;
  gap: 10px;

  .firstInfoContainer {
    @extend .baseInfoContainer;
    [class~='MuiTypography-root'] {
      color: $pendingColor;
    }
    [class~='titleIcon1'] {
      background: linear-gradient(180deg, #52a4cc 0%, rgba(82, 164, 204, 0.31) 100%);
    }
    [class~='footer'] {
      background-color: $pendingColor;
    }
  }
}
