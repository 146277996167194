@use 'sass:color';
@import '../../../common/styles/variables.module';
@import '../../../common/styles/common.module.scss';

.firstInfoContainer {
  @extend .baseInfoContainer;
  margin-bottom: 0 !important;
  [class~='MuiTypography-root'] {
    color: $pendingColor;
  }
  [class~='titleIcon2'] {
    color: $pendingColor;
  }
  [class~='footer'] {
    background-color: $pendingColor;
  }
}

.secondInfoContainer {
  @extend .baseInfoContainer;
  margin-bottom: 0 !important;
  [class~='MuiTypography-root'] {
    color: $warningColor;
  }
  [class~='titleIcon2'] {
    color: $warningColor;
  }
  [class~='footer'] {
    background-color: $warningColor;
  }
}

.thirdInfoContainer {
  @extend .baseInfoContainer;
  margin-bottom: 0 !important;
  [class~='MuiTypography-root'] {
    color: $errorColor;
  }
  [class~='titleIcon2'] {
    color: $errorColor;
  }
  [class~='footer'] {
    background-color: $errorColor;
  }
}

.fourthInfoContainer {
  @extend .baseInfoContainer;
  margin-bottom: 0 !important;
  [class~='MuiTypography-root'] {
    color: #39bdaf;
  }
  [class~='titleIcon2'] {
    color: #39bdaf;
  }
  [class~='footer'] {
    background-color: #39bdaf;
  }
}

.dashboard {
  display: flex;
  flex-direction: column;
}

.start {
  margin: 20px 20px 10px;
  color: $simplewhite;
  .startTitle {
    margin-left: 15px;
    font-weight: bold;
  }
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  margin-right: 10px;
  margin-bottom: 5px;
  .dates {
    display: flex;
    flex-direction: row;
    gap: 8px;
    [class~='MuiOutlinedInput-input'] {
      padding: 5px 5px 5px 55px;
    }
    [class~='MuiOutlinedInput-notchedOutline'] {
      border: none;
    }
  }
  [class~='MuiSelect-select'] {
    padding: 3px 10px;
    background-color: $simplewhite;
  }
  [class~='MuiOutlinedInput-input'] {
    padding: 3px 10px;
    padding-right: 5px;
    padding-left: 55px;
    border: none;
  }
  [class~='MuiOutlinedInput-root'] {
    border: none;
  }
  [class~='MuiOutlinedInput-root']:hover {
    [class~='MuiOutlinedInput-notchedOutline'] {
      border-color: rgb(0, 0, 0, 0.5);
    }
  }
  [class~='MuiInputLabel-root'] {
    top: 16px;
  }
  [class~='MuiInputBase-input'] {
    background-color: $simplewhite;
    border-radius: 11px;
  }
  @media only screen and (max-width: $breakpointMedium) {
    margin-bottom: 0;
    margin-top: 5px;
    [class~='MuiOutlinedInput-input'] {
      padding: 3px 10px;
      padding-right: 5px;
      padding-left: 10px;
      border: none;
    }
    [class~='MuiInputLabel-root'] {
      display: none;
    }
  }
}

.chartsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 14px;
  column-gap: 14px;
}

.chart {
  height: 440px !important;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 30px 10px 30px !important;
  width: calc(50% - 7px);
  .chartDiv {
    height: calc(100% - 80px) !important;
  }

  text {
    font-family: $fonts !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #cbcbcb !important;
  }

  [class~='MuiTableCell-root'] {
    border-bottom: 0;
    color: $primaryDarkerColor;
  }

  [class~='MuiTableCell-head'] {
    background-color: color.scale($lightGray, $lightness: 80%);
    color: #0d3a56;
    border: none;
    max-height: 34px;
    &:first-child {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }
    &:last-child {
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }
  }

  [class~='MuiTableCell-head'] {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }
  [class~='MuiTableCell-body'] {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  @media only screen and (max-width: $breakpointMedium) {
    width: calc(100% - 7px);
    padding: 10px 10px 5px 10px !important;
    .chartDiv {
      margin-left: -40px;
    }
  }
}

.transactionTable {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 30px 10px 30px !important;

  [class~='MuiTableCell-root'] {
    border-bottom: 0;
    color: $primaryDarkerColor;
  }

  [class~='MuiTableCell-head'] {
    background-color: color.scale($lightGray, $lightness: 80%);
    color: #0d3a56;
    border: none;
    max-height: 34px;
    &:first-child {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }
    &:last-child {
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }
  }

  [class~='MuiTableCell-head'] {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
  [class~='MuiTableCell-body'] {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
}

.cardTitle[class~='MuiTypography-root'] {
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  padding-bottom: 20px;
}

.buttonProductImage {
  min-width: 0;
  border-radius: 50%;
  height: fit-content;
  width: fit-content;
  background: $errorColor;
}

.paperTotal {
  width: 100%;
  padding-right: 10px;

  border-radius: $borderRadius;
  background-color: $tableHeaderColor;

  color: $primaryDarkerColor;
}

.titleDebtLayout {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  width: 100%;
}

.titleDebtPopup {
  width: 100%;
  color: $primaryDarkerColor;
}

.dashboardContainer {
  [class~='MuiGrid-item'] {
    padding: 7px;
  }

  [class~='MuiContainer-root'] {
    border-radius: $borderRadius;
    height: 250px;
  }
}

.chipBar {
  width: 12px;
  height: 20px;
  border-radius: 3px 3px 0 0;
  margin: 0 5px 5px 0;
  background-color: $pendingColor;
}

.chipLine {
  width: 20px;
  height: 3px;
  margin: 5px;
  background-color: #4653e3;
}

.gridRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  [class~='MuiTypography-body1'] {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }
}

.table {
  [class~='MuiTableCell-head'] {
    background-color: color.scale($lightGray, $lightness: 80%);
    color: #0d3a56;
    border: none;
    max-height: 34px;
    &:first-child {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }
    &:last-child {
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }
  }
}

.emptyChart {
  margin: auto;
  color: $primaryDarkerColor;
  font-weight: 600;
}
