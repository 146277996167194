@import '../../../../common/styles/variables.module';

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 50px 89px 30px 63px;
  justify-content: center;
  gap: 85px;
  @media only screen and (max-width: $breakpointMedium) {
    flex-direction: column;
    width: 100vw;
    padding: 0;
    gap: 30px;
  }
}

.productDisplayContainer {
  width: 443px;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  top: 0;
  @media only screen and (max-width: $breakpointMedium) {
    width: 90%;
    margin: auto;
  }
}

.paymentsContainer {
  width: 570px;
  display: flex;
  flex-direction: column;
  align-items: center;

  [class~='containerPaymentGateways'] {
    width: 100%;
  }

  [class~='paymentMethods'] {
    width: 95%;
  }
  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
  }
}

.returnButton {
  margin-top: 12px;
  width: 170px;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
}
