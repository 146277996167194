@import '../styles/variables.module';

.popUpPaper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 40%;
  max-height: 90vh;
  overflow: scroll;

  @media only screen and (max-width: $breakpointMedium) {
    width: 80%;
  }
}

.gridImage {
  display: flex;
  justify-content: center;
  position: relative;
  top: -50px;

  .paperImage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    border-radius: 50%;

    .containerImage {
      width: 95%;
      height: 95%;
      border-radius: 50%;
      background-color: $secondaryColor;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0 !important;
    }
  }
}

.closePopup {
  height: 10px;
  width: 10px;
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
}

.contentGrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.buttonGrid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}
