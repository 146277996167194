@use 'sass:color';

// COLORS
$seablue: #4296ca;
$calypso: #5fb9bf;
$primaryBlue: #4653E3;

$simplegray: #110b11;
$lightGray: #979797;
$lightygray: #b9b9b9;
$slightyLightyGray: #E7E7E7;
$lightergray: #f9f9f9;
$simplewhite: #ffffff;

$successColor: #6DD1A7;
$warningColor: #ecb81b;
$errorColor: #E56565;
$pendingColor: #00A3FE;
$infoColor: #00A3FE;
$chartColor: #ecb81b;

$successLighterColor: color.scale(#6DD1A7, $lightness: 60%);
$warningLighterColor: color.scale(#ecb81b, $lightness: 60%);
$errorLighterColor: color.scale(#E56565, $lightness: 60%);
$pendingLighterColor: color.scale(#00A3FE, $lightness: 60%);
$infoLighterColor: color.scale(#00A3FE, $lightness: 60%);

$tableHeaderColor: rgba(10, 56, 83, 0.06);

$primaryColor: $seablue;
$primaryLightColor: color.scale($primaryColor, $lightness: 10%);
$primaryLighterColor: color.scale($primaryColor, $lightness: 50%);
$primaryLightestColor: color.scale($primaryColor, $alpha: -90%);
$primaryDarkColor: color.scale($primaryColor, $lightness: -25%);
$primaryDarkerColor: color.scale($primaryColor, $lightness: -50%);
$primaryDarkestColor: color.scale($primaryColor, $lightness: -70%);
$primaryContrastText: $simplewhite;

$secondaryColor: $calypso;
$secondaryLightColor: color.scale($secondaryColor, $lightness: 10%);
$secondaryLighterColor: color.scale($secondaryColor, $lightness: 50%);
$secondaryDarkColor: color.scale($secondaryColor, $lightness: -25%);
$secondaryDarkerColor: color.scale($secondaryColor, $lightness: -50%);
$secondaryContrastText: $simplewhite;

$primaryTextColor: #ffffff;
$secondaryTextColor: #37474f;

$greyColor: #cfd8dd;
$greyDarkColor: #62717b;
$greyLightColor: #e0e3e5;

$yellowColor: #B87D3D;
$yellowLightColor: color.scale($yellowColor, $lightness: 25%);
$yellowLighterColor: color.scale($yellowColor, $lightness: 50%);
$yellowLightestColor: color.scale($yellowColor, $lightness: 75%);
$yellowDarkColor: color.scale($yellowColor, $lightness: -25%);

// Defaults
$fonts: 'Lato', Sans-Serif;
$borderRadius: 10px;
$sidebarWidth: 289px;
$sidebarClosedWidth: 72px;
$animTime: 333ms;
$animTimeFast: 250ms;
$imageSize: 42px;
$bigImageSize: 131px;
$borderWidth: 1px;
$actionsWidth: 110px;
$containerHeight: 170px;
$iconsCircleDiameter: 40px;
$containerImageMaxHeight: 143px;
$smallSquareIconSize: 24px;

// Sizes
$breakpointExtraSmall: 0px;
$breakpointSmall: 600px;
$breakpointMedium: 900px;
$breakpointLarge: 1350px;
$breakpointExtraLarge: 1536px;

:export {
  simplegray: $simplegray;
  lightGray: $lightGray;
  lightygray: $lightygray;
  slightyLightyGray: $slightyLightyGray;
  lightergray: $lightergray;
  simplewhite: $simplewhite;
  primaryBlue: $primaryBlue;

  animTime: $animTime;

  primary: $primaryColor;
  primaryLight: $primaryLightColor;
  primaryDark: $primaryDarkColor;
  primaryText: $primaryContrastText;

  primaryLighterColor: $primaryLighterColor;
  primaryLightestColor: $primaryLightestColor;
  primaryDarkColor: $primaryDarkColor;
  primaryDarkerColor: $primaryDarkerColor;
  primaryDarkestColor: $primaryDarkestColor;
  primaryContrastText: $primaryContrastText;

  tableHeaderText: $tableHeaderColor;

  secondary: $secondaryColor;
  secondaryLight: $secondaryLightColor;
  secondaryDark: $secondaryDarkColor;
  secondaryText: $secondaryContrastText;

  success: $successColor;
  warning: $warningColor;
  error: $errorColor;
  pending: $pendingColor;
  info: $infoColor;
  chart: $chartColor;

  green: $successLighterColor;
  red: $errorLighterColor;
  blue: $pendingLighterColor;
  yellow: $warningLighterColor;

  fonts: $fonts;
  borderRadius: $borderRadius;
  borderWidth: $borderWidth;
  containerHeight: $containerHeight;
  iconsCircleDiameter: $iconsCircleDiameter;
  containerImageMaxHeight: $containerImageMaxHeight;
  smallSquareIconSize: $smallSquareIconSize;

  breakpointExtraSmall: $breakpointExtraSmall;
  breakpointSmall: $breakpointSmall;
  breakpointMedium: $breakpointMedium;
  breakpointLarge: $breakpointLarge;
  breakpointExtraLarge: $breakpointExtraLarge;
}
