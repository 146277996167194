@import '../../../common/styles/variables.module';

[class~='MuiContainer-root'] {
  padding: 0;
  margin: 0;
}

.baseContainer {
  display: flex;
  flex-direction: row;
  background-color: #e9feff !important;
  min-height: 100vh;

  @media only screen and (max-width: $breakpointMedium) {
    flex-direction: column;
  }

  .stepperContainer {
    // ojo
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30vw;

    .stepperContainerLayout {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: $breakpointMedium) {
        flex-direction: column;
      }

      .stepperGrid {
        margin-left: 2%;
        width: auto;
        height: 480px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        @media only screen and (max-width: $breakpointMedium) {
          flex-direction: row;
          height: auto;
          width: 100%;
          margin-left: 0;
          margin-top: 2%;
          align-items: center;
          justify-content: space-evenly;
        }
      }
    }

    @media only screen and (max-width: $breakpointMedium) {
      width: 100%;
      height: 20vh;
    }
  }

  .container {
    // ojo
    display: flex;
    flex-direction: column;
    width: 70vw;

    @media only screen and (max-width: $breakpointMedium) {
      width: 100%;
    }
    .setupPaper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // padding-top: 3%;
      border-radius: 0;

      [class~='MuiTypography-h3'] {
        font-family: $fonts;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        color: $primaryDarkerColor;
      }

      [class~='MuiTypography-h5'] {
        font-family: $fonts;
        font-size: 18px;
        font-style: normal;
        color: $primaryDarkerColor;
      }

      @media only screen and (max-width: $breakpointMedium) {
        [class~='MuiTypography-h3'] {
          font-size: 18px;
        }

        [class~='MuiTypography-h5'] {
          font-size: 10px;
        }
      }
    }
  }
}

.gridPaperContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  height: 100vh;
  overflow-y: scroll;

  @media only screen and (max-width: $breakpointMedium) {
    overflow-y: visible;
    height: auto;
    min-height: 80vh;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: 3%;
  margin-bottom: 3%;

  [class~='MuiGrid-item'] {
    margin: 3px 0;
  }

  @media only screen and (max-width: $breakpointMedium) {
    width: 90%;
    margin: 10px 0;
  }
}

.gridBanner {
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30vh;
  justify-content: space-evenly;
  width: 100%;
  background-color: #f1fffe;

  @media only screen and (max-width: $breakpointMedium) {
    height: 15vh;
    justify-content: space-evenly;
  }
}

.logoCompany {
  width: 95%;
  height: 95%;
}

.logoBanner {
  width: 100px;
  height: 100px;
  background-color: #64c0bc;
  @media only screen and (max-width: $breakpointMedium) {
    width: 50px;
    height: 50px;
  }
}

.containerChoice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .containerPaperChoice {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    [class~='MuiTypography-h6'] {
      font-family: $fonts;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      color: $primaryDarkerColor;
    }

    @media only screen and (max-width: $breakpointMedium) {
      width: 100%;

      [class~='MuiTypography-h6'] {
        font-size: 14px;
      }
    }

    .itemPaperChoice {
      width: 250px;
      height: 250px;
      margin: 10px;

      @media only screen and (max-width: $breakpointMedium) {
        width: 128px;
        height: 150px;
      }
    }
  }

  .containerButtonChoice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    [class~='MuiGrid-item'] {
      margin: 10px;
      width: 100%;
    }
  }
}

.paperChoice {
  border-radius: 10%;
  height: 100%;

  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
  }
}

.paperChoice:hover {
  background-color: #e9feff !important;
  [class~='MuiTypography-h6'] {
    color: $primaryDarkerColor !important;
  }
}

.paperSelected {
  background-color: #7cd6dc !important;
  border-radius: 10%;
  height: 100%;

  [class~='MuiTypography-h6'] {
    color: #ffffff !important;
  }

  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
  }
}

.gridLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.logoChoiceEmpresa {
  width: 77px;
  height: 109px;
  @media only screen and (max-width: $breakpointMedium) {
    width: 43px;
    height: 61px;
  }
}

.logoChoicePersona {
  width: 110px;
  height: 107px;
  @media only screen and (max-width: $breakpointMedium) {
    width: 57px;
    height: 56px;
  }
}

.progressBarFull {
  background-image: url('https://storage.googleapis.com/onlypays-public/assets/images/fullProgress.svg');
  transition: height 2s;
  @media only screen and (max-width: $breakpointMedium) {
    transition: width 2s;
    background-image: url('https://storage.googleapis.com/onlypays-public/assets/images/fullProgressMobile.svg');
  }
}

.progressBarEmpty {
  background-image: url('https://storage.googleapis.com/onlypays-public/assets/images/emptyProgress.svg');
  @media only screen and (max-width: $breakpointMedium) {
    background-image: url('https://storage.googleapis.com/onlypays-public/assets/images/emptyProgressMobile.svg');
  }
}

.stepSelected {
  color: #36b8c1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  @media only screen and (max-width: $breakpointMedium) {
    font-size: 10px;
  }
}

.stepUnSelected {
  color: #99dce0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  @media only screen and (max-width: $breakpointMedium) {
    font-size: 10px;
  }
}

.mainGrid {
  @media only screen and (max-width: $breakpointMedium) {
    [class~='MuiInputBase-input'] {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    [class~='MuiGrid-root'] {
      padding-top: 5px;
    }
    [class~='MuiOutlinedInput-root'] {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  .formTitle {
    [class~='MuiTypography-h3'] {
      font-family: $fonts;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      color: $primaryDarkerColor;
    }

    @media only screen and (max-width: $breakpointMedium) {
      [class~='MuiTypography-h3'] {
        font-size: 20px;
      }
    }
  }

  .gridProductImage {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;

    @media only screen and (max-width: $breakpointMedium) {
      justify-content: center;
    }

    .paperProductImage {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 150px;
      width: 150px;
      border-radius: 50%;

      .containerProductImage {
        width: 95%;
        height: 95%;
        border-radius: 50%;
        background-color: #30a3ab;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 0 !important;

        .buttonProductImage {
          z-index: 1;
          min-width: 0;
          border-radius: 50%;
          height: fit-content;
          width: fit-content;
          background: rgba(88, 177, 194, 0.7);
          position: relative;
          top: 20px;
        }

        .logoProduct {
          position: relative;
          z-index: 0;
          height: 100%;
          width: 100%;
          top: 30px;
          border-radius: 50%;
        }
      }
    }
  }

  .paperImage {
    height: 200px;
    width: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #e6e6e6;
    border-radius: 10%;
    @media only screen and (max-width: $breakpointMedium) {
      height: 100px;
      width: 100px;
    }
  }
}

.responsiveIframe {
  margin-left: 0;
  zoom: 0.5;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: $breakpointMedium) {
    zoom: 0.9;
    transform: scale(0.7);
    box-shadow: unset;
  }
  [class~='MuiTypography-root'] {
    color: '#1f1f1f' !important;
  }
}

.informationContainer {
  padding: 20px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  .header {
    [class~='MuiTypography-root'] {
      color: $primaryDarkColor;
      font-weight: 700;
    }
    @media only screen and (max-width: $breakpointMedium) {
      display: flex;
      flex-direction: column;
    }
  }
  .actions {
    @media only screen and (max-width: $breakpointMedium) {
      display: flex;
    }
  }
}

.drawerContainer {
  width: 33vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  .drawerField {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    [class~='MuiTextField-root'] {
      width: 100%;
    }
  }
  .actions {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
  .centeredActions{
    margin-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  [class~='MuiIconButton-root'] {
    color: $primaryColor;
  }

  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 60px;
    width: 100vw;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  .components {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 15px;
    @media only screen and (max-width: $breakpointMedium) {
      flex-direction: column;
      width: 100%;
    }
  }
  .formContainer {
    width: 50%;
    @media only screen and (max-width: $breakpointMedium) {
      width: 100%;
    }
  }
}

.previewBoxContainer {
  width: 100%;
  @media only screen and (max-width: $breakpointMedium) {
    min-width: 100%;
    margin-top: -250px;
    margin-bottom: -150px;
  }
}

.companyDesignColors {
  display: flex;
  flex-direction: column;
  margin: 20px 25px;
  width: 100%;
  @media only screen and (max-width: $breakpointMedium) {
    min-width: calc(100% - 40px);
  }
}

.paymentMethodsContainer {
  display: flex;
  flex-direction: column;
}

.avatarContainer {
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
  @media only screen and (max-width: $breakpointMedium) {
    height: 80px;
    width: 80px;
  }
}

.infoPaper {
  padding: 20px;
  [class~='MuiTypography-overline'] {
    line-height: initial;
    color: $primaryDarkColor;
  }
}

.emailContainer {
  padding: 20px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  [class~='MuiTypography-root'] {
    color: $primaryDarkColor;
  }
  [class~='MuiTypography-h1'] {
    color: $primaryDarkerColor;
    font-size: 20px;
    font-weight: 500;
  }
  .emailRow {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 100px;
    .inputContainer {
      display: flex;
      flex-direction: column;
      width: 50%;
      [class~='MuiTypography-h6'] {
        color: $primaryDarkerColor;
        font-size: 17px;
      }
      .saveButton {
        margin-top: 20px;
        width: 200px;
      }
      [class~='MuiInputBase-root'] {
        padding: 0;
      }
      [class~='MuiInputBase-input'] {
        padding: 12px 10px;
      }
      [class~='MuiSkeleton-root'] {
        -webkit-transform: unset;
        transform: unset;
      }
      b {
        cursor: copy;
      }
    }
    .previewContainer {
      padding: 20px 40px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-direction: column;
      width: 45%;
      border-radius: 10px;
      [class~='MuiTypography-root'] {
        color: black;
      }
      .subject {
        height: 60px;
      }
      .topBar {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        img {
          height: 40px;
          width: 40px;
          border-radius: 20px;
          margin: auto;
        }
      }
      .companyLogo {
        height: 40px;
        width: 40px;
        border-radius: 4px;
        margin: auto;
      }
      .titleContainer {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        gap: 15px;
        [class~='MuiTypography-h6'] {
          font-size: 16px;
          margin: auto;
          font-weight: 600;
        }
        [class~='MuiTypography-h5'] {
          font-size: 18px;
          margin: auto;
          font-weight: 600;
        }
      }
      [class~='MuiTypography-h4'] {
        margin-left: 24%;
        margin-top: 30px;
        font-size: 14px;
        font-weight: 600;
      }
      .amount {
        margin: 10px auto 10px;
        [class~='MuiTypography-h6'] {
          font-size: 25px;
          margin: auto;
          font-weight: 500;
        }
        .recurrence {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .messages {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 75%;
        margin: auto;
        margin-top: 15px;
        [class~='MuiTypography-root'] {
          margin: auto;
          font-size: 11px;
          font-weight: 400;
          text-align: center;
        }
      }
      .summaryTable {
        margin: auto;
        margin-top: 10px;
        padding: 10px 5px;
        border: 1px solid #b9b9b9;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 1px;
        width: 50%;
        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          [class~='MuiTypography-root'] {
            font-size: 11px;
          }
          [class~='MuiTypography-h6'] {
            font-weight: 600;
          }
        }
      }
      [class~='MuiTypography-h5'] {
        font-size: 18px;
        margin: auto;
        margin-top: 10px;
        font-weight: 600;
        width: 50%;
        text-align: center;
      }
      .personalizedParagraph {
        margin-top: 10px;
        [class~='MuiTypography-root'] {
          margin: auto;
          width: 60%;
          font-size: 11px;
          text-align: center;
        }
      }
      .checkIcon {
        margin: 12px auto 12px;
        img {
          height: 50px;
          width: 50px;
        }
      }
      .payButton {
        margin: 30px auto 20px;
        font-size: 13px;
        background-color: #c43d2c;
        border-radius: 60px;
        padding: 8px 18px;
        font-weight: bold;
        border-style: none;
        [class~='MuiTypography-root'] {
          color: #ffffff;
          font-size: 10px;
        }
      }
      .contact {
        [class~='MuiTypography-root'] {
          margin: auto;
          width: 50%;
          font-size: 8px;
          text-align: center;
        }
      }
      .powered {
        margin: 10px auto 20px;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
        [class~='MuiTypography-root'] {
          font-size: 8px;
        }
        img {
          max-width: 60px;
        }
      }
      .footer {
        padding: 8px 15px;
        margin-top: 5px;
        width: calc(100% - 30px);
        height: 80px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        [class~='MuiTypography-root'] {
          color: white;
        }
        [class~='MuiTypography-h5'] {
          margin: auto;
          margin-top: 0;
          font-size: 9px;
          font-weight: 400;
        }
        [class~='MuiTypography-h6'] {
          font-size: 6px;
          margin: auto;
          font-weight: 400;
          text-align: center;
        }
        img {
          margin: auto;
          max-width: 100px;
        }
      }
    }
    @media only screen and (max-width: $breakpointMedium) {
      flex-direction: column;
      gap: 15px;
      .inputContainer {
        width: 100%;
      }
      .previewContainer {
        padding: 4px;
        width: 100%;
        .subject {
          height: 100px;
        }
        .messages {
          width: 80%;
        }
        .summaryTable {
          width: 75%;
        }
        [class~='MuiTypography-h4'] {
          margin-left: 12%;
        }
        .personalizedParagraph {
          [class~='MuiTypography-root'] {
            width: 80%;
          }
        }
        .contact {
          [class~='MuiTypography-root'] {
            width: 80%;
          }
        }
        .footer {
          width: calc(100% - 30px);
          [class~='MuiTypography-h5'] {
            width: 80%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    padding: 12px;
  }
}

.shopifyButtonContainer {
  width: 100%;
  margin-left: 100px;
  position: relative;
  .shopifyButton {
    font-family: 'Lato', Sans-Serif;
    width: 400px;
    height: 90px;
    display: flex;
    flex-direction: column;
    border: 1px solid #4653e3;
    overflow: hidden;
    position: sticky;
    top: 0;
    margin-top: 40px;
    .div1 {
      width: calc(100% - 30px);
      height: 30px;
      padding: 10px 17px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .bottomRow {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      .div2 {
        width: 75px;
        padding: 10px 7px 10px 17px;
        font-size: 14px;
        @media only screen and (max-width: $breakpointMedium) {
          font-size: 12px;
        }
      }
      .div3 {
        width: calc(100% - 89px);
        padding: 10px 5px;
        font-size: 14px;
        @media only screen and (max-width: $breakpointMedium) {
          font-size: 12px;
        }
      }
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    margin-left: 10px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    .shopifyButton {
      width: 100%;
      margin: 0;
    }
  }
}

.inputsSection {
  display: flex;
  flex-direction: column;
  margin: 20px 25px;
  width: 100%;
  [class~='MuiTextField-root'] {
    input {
      padding: 10px 15px;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    min-width: calc(100% - 40px);
  }
}
.rowGroup {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  .group {
    display: flex;
    flex-direction: column;
    width: calc(50% - 8px);
    @media only screen and (max-width: $breakpointMedium) {
      min-width: calc(100% - 40px);
    }
  }
}

.loadImage {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .imageContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
}

.imagePreview {
  max-height: 100px;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.loadButton {
  height: 40px;
}

.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  row-gap: 15px;
  width: 100%;
}

.emailsListContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.saveButton {
  width: 200px;
}

.centerRow {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
