@import '../../common/styles/variables.module';

.slidesContainer {
  position: relative;
  .views {
    margin: 0 5px 0 5px;
  }
  .absoluteContainerLeft {
    position: absolute;
    top: calc($containerHeight / 2 - 20px);
    left: -10px;
    [class~=Mui-disabled] {
      display: none;
    }
  }
  .absoluteContainerRight {
    position: absolute;
    top: calc($containerHeight / 2 - 20px);
    right: -10px;
    [class~=Mui-disabled] {
      display: none;
    }
  }
  .iconButton {
    width: 40px;
    height: 40px;
    background-color: $primaryLightColor;
    backdrop-filter: blur(4px);
    color: $simplewhite;
  }
}

.stepper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: none;
  padding-top: 0;

  [class~=MuiMobileStepper-dot] {
    background-color: transparent;
    border: 1px solid $simplewhite;
  }
  [class~=MuiMobileStepper-dotActive] {
    background-color: $simplewhite;
  }
}

