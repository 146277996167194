@use 'sass:color';
@import '../../common/styles/variables.module';
@import '../../common/styles/common.module.scss';

$gradient: linear-gradient(
  180deg,
  color.adjust($secondaryColor, $alpha: -0.85) 0%,
  $secondaryColor 80%
);
$gradientMobile: linear-gradient(
  180deg,
  color.adjust($secondaryColor, $alpha: -0.75) 0%,
  $secondaryColor 120%
);

body {
  background-color: $primaryDarkestColor;
  background: linear-gradient(180deg, #0d3b56 0%, #002f4b 100%);
}

.drawer {
  [class~='MuiDrawer-paper'] {
    width: calc($sidebarWidth - 16px);
    height: calc(100vh - 2 * $borderRadius);
    overflow-y: auto;
    background: $gradient;
    color: $simplewhite;
    border: none;
    padding: $borderRadius;
    margin: $borderRadius;
    border-radius: $borderRadius;
    transition: width $animTime ease-out;
    margin-bottom: $borderRadius;

    [class~='MuiListItem-root'] {
      border-radius: $borderRadius;
      margin-bottom: 3px;
      padding: 12px 4px;
      [class~='MuiTypography-root'] {
        font-size: 20px;
        line-height: 24px;
        color: $simplewhite;
      }
      [class~='MuiListItemIcon-root'] {
        display: flex;
        justify-content: center;
        svg {
          color: $simplewhite;
        }
      }

      &[class~='Mui-selected'] {
        [class~='MuiTypography-root'] {
          color: $primaryColor;
        }
        background-color: $simplewhite;
        svg {
          color: $primaryColor;
        }
      }
    }

    @media only screen and (max-width: $breakpointMedium) {
      background: $primaryDarkestColor;
    }
  }
  &.drawerClosed [class~='MuiDrawer-paper'] {
    width: $sidebarClosedWidth;
    [class~='MuiListItem-root'] {
      padding: 16px 0px;
    }
    [class~='MuiListItemText-root'] {
      display: none;
    }
  }

  .topDrawer {
    display: flex;
    justify-content: space-between;
    margin-top: $borderRadius;
    align-items: center;

    .hamburguerButton {
      border-radius: $borderRadius;
      background-color: $simplewhite;
      padding: 4px;
      svg {
        height: 25px;
        width: 25px;
        color: $primaryDarkerColor;
      }
      &:hover {
        background-color: $primaryLightestColor;
        svg {
          color: $simplewhite;
        }
      }
    }

    .homeLink {
      height: 50px;
      img {
        height: 100%;
      }
    }

    &.topDrawerClosed {
      justify-content: center;
      .homeLink {
        display: none;
      }
    }

    &.topDrawerClosed {
      justify-content: center;
      .homeLink {
        display: none;
      }
    }
  }

  .avatar {
    margin: 50px $borderRadius $borderRadius $borderRadius;
    justify-content: center;
    width: $bigImageSize;
    height: $bigImageSize;
    @media only screen and (max-width: $breakpointMedium) {
      display: none;
    }
    img {
      border-radius: 50%;
    }
    &.topDrawerClosed {
      width: $imageSize;
      height: $imageSize;
    }
  }

  .avatar {
    margin: 50px $borderRadius $borderRadius $borderRadius;
    justify-content: center;
    width: $bigImageSize;
    height: $bigImageSize;
    img {
      border-radius: 50%;
    }
    &.topDrawerClosed {
      width: $imageSize;
      height: $imageSize;
    }
  }
}

.mainContainer {
  transition: margin $animTime ease-out;
  [class~='MuiContainer-root'] {
    padding-left: 0;
    padding-right: $borderRadius;
  }
  margin-left: $sidebarWidth;

  @media only screen and (max-width: $breakpointMedium) {
    padding: 0;
    margin: 0;
    [class~='MuiContainer-root'] {
      padding: 0;
      margin: 0;
    }
  }
}

.mainContainerWhenClosed {
  @media only screen and (min-width: $breakpointMedium) {
    margin-left: calc($sidebarClosedWidth + 16px);
  }
}

.dashBody {
  max-width: 100%;
  height: calc(100vh - 120px);
  padding: 20px;
  margin: 10px 10px 0px auto;
  background-color: $primaryLightestColor;
  background: $gradient;
  overflow-x: hidden;
  overflow-y: scroll;

  transition: width $animTime ease-out;

  @media only screen and (max-width: $breakpointMedium) {
    margin: 0;
    background: $gradientMobile;
    height: calc(100vh - 96px);
    border-radius: 0;
  }
}

.dashboardCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 35px;
  border: 1px solid $simplewhite;
  border-radius: $borderRadius;

  [class~='MuiTypography-root'] {
    color: $simplewhite;
  }

  .dashboardCardIcon {
    height: 32px;
    width: 32px;
    color: $simplewhite;
  }
  .dashboardCardTitle[class~='MuiTypography-root'] {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .dashboardCardValue[class~='MuiTypography-root'] {
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
  }
}

.firstInfoContainer {
  @extend .baseInfoContainer;
  margin-bottom: $borderRadius !important;
  margin-right: 10px;
  [class~='titleIcon1'] {
    margin-top: auto;
    color: $lightGray;
    svg {
      height: 1.5em;
    }
  }
  [class~='MuiTypography-root'] {
    color: $lightGray;
  }
  [class~='titleIcon2'] {
    color: $lightGray;
  }
  [class~='footer'] {
    background-color: $lightGray;
  }
}

.secondInfoContainer {
  @extend .baseInfoContainer;
  margin-bottom: $borderRadius !important;
  margin-right: 10px;
  [class~='titleIcon1'] {
    margin-top: auto;
    color: $warningColor;
    svg {
      height: 1.5em;
    }
  }
  [class~='MuiTypography-root'] {
    color: $warningColor;
  }
  [class~='titleIcon2'] {
    color: $warningColor;
  }
  [class~='footer'] {
    background-color: $warningColor;
  }
}

.thirdInfoContainer {
  @extend .baseInfoContainer;
  margin-bottom: $borderRadius !important;
  margin-right: 10px;
  [class~='titleIcon1'] {
    margin-top: auto;
    color: $pendingColor;
    svg {
      height: 1.5em;
    }
  }
  [class~='MuiTypography-root'] {
    color: $pendingColor;
  }
  [class~='titleIcon2'] {
    color: $pendingColor;
  }
  [class~='footer'] {
    background-color: $pendingColor;
  }
}

.chart {
  height: 400px !important;
  min-width: 100% !important;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 30px 10px 30px !important;
  border-radius: $borderRadius;
  margin-bottom: $borderRadius;

  text {
    font-family: $fonts !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #cbcbcb !important;
  }

  [class~='MuiTableCell-root'] {
    border-bottom: 0;
    color: $primaryDarkerColor;
  }

  [class~='MuiTableCell-head'] {
    background-color: color.scale($lightGray, $lightness: 80%);
    color: #0d3a56;
    border: none;
    max-height: 34px;
    &:first-child {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }
    &:last-child {
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }
  }

  [class~='MuiTableCell-head'] {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }
  [class~='MuiTableCell-body'] {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}
