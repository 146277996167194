@use 'sass:color';
@import '../../styles/variables.module';

.modal {
  padding: 50px 30px;
  text-align: center;

  .modalHeader {
    margin-bottom: 50px;
  }
  svg {
    margin-bottom: 30px;
  }
  [class~='MuiButton-root'] {
    margin: 20px;
  }

  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 2vh;
    [class~='MuiTypography-h4'] {
      font-size: 1.5rem;
    }
    .modalHeader {
      margin-bottom: 20px;
    }
    svg {
      margin-bottom: 10px;
    }
    [class~='MuiButton-root'] {
      margin: 10px;
    }
  }
}