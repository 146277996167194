@use 'sass:color';
@import '../../common/styles/variables.module';

.accordionContainer {
  width: 100%;
  [class~='MuiAccordionSummary-content'] {
    margin: 5px 0;
  }
  @media only screen and (max-width: $breakpointMedium) {
    max-height: 50vh;
    overflow-y: scroll;
  }
}

.textContainer {
  @media only screen and (max-width: $breakpointMedium) {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
}

.accordion {
  width: 100%;
  background-color: $lightergray;
  border-radius: $borderRadius;
  border: 0;
  box-shadow: none;
  margin-bottom: 10px;
}

[class~='popUpPaper'] {
  width: 60%;
}

.extraFieldsGrid {
  margin-bottom: 5px;
}

.title {
  margin-bottom: 5px;
}

.subtitle {
  margin-bottom: 15px;
  font-size: 20px;
}

.cancelButton {
  border-color: $primaryDarkerColor;
  color: $primaryDarkerColor;
}

.acceptButton {
  color: white;
  @media only screen and (min-width: $breakpointMedium) {
    width: 35%;
  }
}

.warningPopUpContentContainer {
  text-align: center;
  [class~='MuiTypography-root'] {
    color: #1f1f1f;
    font-family: 'Poppins';
  }
}

.suggestionOptionsContainer {
  [class~='MuiTypography-root'] {
    color: #1f1f1f;
    font-family: 'Poppins';
  }
}
