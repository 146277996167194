@use 'sass:color';
@import '../../common/styles/variables.module';

.loaderContainer {
  position: absolute;
  background: color.adjust($lightergray, $alpha: -0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: $borderRadius;
  z-index: 10000;

  img {
    width: 50vw;
    max-width: 500px;
    animation: pulse 0.666s infinite;
    animation-direction: alternate;
    animation-name: pulse;
  }

  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.whiteSquare {
  width: 700px;
  height: 500px;
  background-color: $simplewhite;
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageCircle {
  margin-top: -60px;
  z-index: 1;
  background-color: $simplewhite;
  border-radius: 50%;
  padding: 5px;
  img {
    width: 120px;
  }
}

.textContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: -60px;
}
