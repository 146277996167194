@import '../variables.module';

.baseInfoContainer {
  background-color: $simplewhite;
  height: $containerHeight;
  margin-bottom: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: $borderRadius;
  [class~='title1'] {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
  }
  [class~='title2'] {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-direction: row;
  }
  [class~='titleIcon1'] {
    width: $iconsCircleDiameter;
    height: $iconsCircleDiameter;
    margin-top: 10px;
    border-radius: 50%;
    color: $simplewhite;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  [class~='titleIcon2'] {
    border-radius: 50%;
    margin-top: 6px;
    margin-right: 8px;
    [class~='icon'] {
      min-height: $smallSquareIconSize;
      min-width: $smallSquareIconSize;
    }
    svg {
      min-height: 20px;
      min-width: 20px;
    }
  }
  [class~='MuiTypography-h6'] {
    font-weight: bold;
  }
  [class~='MuiTypography-h3'] {
    font-weight: bold;
  }
  [class~='mainData1'] {
    padding-left: calc($iconsCircleDiameter + 15px);
    display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    align-items: flex-start;
    [class~='trendContainer'] {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    [class~='error'] {
      color: $errorColor;
      [class~='MuiTypography-root'] {
        color: $errorColor;
      }
    }
    [class~='success'] {
      color: $successColor;
      [class~='MuiTypography-root'] {
        color: $successColor;
      }
    }
  }
  [class~='mainData2'] {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    align-items: flex-start;
    [class~='trendContainer'] {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 10px;
    }
    [class~='error'] {
      color: $errorColor;
      [class~='MuiTypography-root'] {
        color: $errorColor;
      }
    }
    [class~='success'] {
      color: $successColor;
      [class~='MuiTypography-root'] {
        color: $successColor;
      }
    }
  }
  [class~='upFooter'] {
    padding: 15px 20px 0px 20px;
    height: calc($containerHeight - $borderRadius - 15px - 30px);
  }
  [class~='footer'] {
    height: $borderRadius;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }
  [class~='tooltip'] {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    [class~='MuiTooltip-root'] {
      font-size: larger;
    }
  }
}
