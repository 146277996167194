@use 'sass:color';
@import '../../../common/styles/variables.module';
@import '../../../common/styles/common.module.scss';

.start {
  margin: 20px;
  color: $simplewhite;
  .startHeader {
    display: flex;
    align-items: center;
    .startTitle {
      margin-left: 15px;
      font-weight: bold;
    }
    svg {
      height: 34px;
    }
  }
}

.containerGrid {
  max-height: calc($containerHeight + 16px + 15px);
  gap: 16px;
}

.toButton {
  width: 100%;
  background: linear-gradient(180deg, #004a77 0%, #003657 100%);
  border-radius: $borderRadius;
  margin: 0 auto 15px auto;
  min-height: 45px;
}

.firstInfoContainer {
  @extend .baseInfoContainer;
  [class~='MuiTypography-root'] {
    color: $pendingColor;
  }
  [class~='titleIcon2'] {
    color: $pendingColor;
  }
  [class~='footer'] {
    background-color: $pendingColor;
  }
}

.linkContainer {
  height: $containerHeight;
  margin-bottom: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: $borderRadius;
  background: $simplewhite;
  [class~='main'] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  [class~='mainImage'] {
    max-height: $containerImageMaxHeight;
    margin-top: 20px;
  }
  [class~='MuiButton-root'] {
    background-color: linear-gradient(180deg, #004a77 0%, #003657 100%);
    border-radius: $borderRadius;
    width: 75%;
    position: relative;
    bottom: calc(15px + $borderRadius);
  }
}
