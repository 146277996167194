@import '../../common/styles/variables.module';

.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  .fieldContainer {
    width: 100%;
    margin-top: 20px;
    .fieldHeader {
      margin-bottom: 5px;
      white-space: nowrap;
    }
    @media only screen and (max-width: $breakpointMedium) {
      margin-top: 10px;
      [class~='MuiTypography-root'] {
        white-space: pre-wrap;
      }
    }
  }
  .inputField {
    width: 100%;
  }
  .optionsInput {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}
