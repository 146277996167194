@use 'sass:color';
@import '../../common/styles/variables.module';

$gradient: linear-gradient(
  180deg,
  color.adjust($secondaryColor, $alpha: -0.85) 0%,
  $secondaryColor 80%
);
$gradientMobile: linear-gradient(
  180deg,
  color.adjust($secondaryColor, $alpha: -0.75) 0%,
  $secondaryColor 120%
);

.appContainer {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1200;
  &.appBarOpen {
    opacity: 0.6;
  }
}

body {
  background-color: $primaryDarkestColor;
  background: linear-gradient(180deg, #0d3b56 0%, #002f4b 100%);
}

.drawer {
  [class~='MuiDrawer-paper'] {
    width: calc($sidebarWidth - 16px);
    height: calc(100vh - 2 * $borderRadius);
    overflow-y: auto;
    background: $gradient;
    color: $simplewhite;
    border: none;
    padding: $borderRadius;
    margin: $borderRadius;
    border-radius: $borderRadius;
    transition: width $animTime ease-out;
    margin-bottom: $borderRadius;

    [class~='MuiListItem-root'] {
      border-radius: $borderRadius;
      margin-bottom: 3px;
      padding: 12px 4px;
      [class~='MuiTypography-root'] {
        font-size: 20px;
        line-height: 24px;
        color: $simplewhite;
      }
      [class~='MuiListItemIcon-root'] {
        display: flex;
        justify-content: center;
        svg {
          color: $simplewhite;
        }
      }

      &[class~='Mui-selected'] {
        [class~='MuiTypography-root'] {
          color: $primaryColor;
        }
        background-color: $simplewhite;
        svg {
          color: $primaryColor;
        }
      }
    }

    @media only screen and (max-width: $breakpointMedium) {
      background: $primaryDarkestColor;
    }
  }
  &.drawerClosed [class~='MuiDrawer-paper'] {
    width: $sidebarClosedWidth;
    [class~='MuiListItem-root'] {
      padding: 16px 0px;
    }
    [class~='MuiListItemText-root'] {
      display: none;
    }
  }

  .topDrawer {
    display: flex;
    justify-content: space-between;
    margin-top: $borderRadius;
    align-items: center;

    .hamburguerButton {
      border-radius: $borderRadius;
      background-color: $simplewhite;
      padding: 4px;
      svg {
        height: 25px;
        width: 25px;
        color: $primaryDarkerColor;
      }
      &:hover {
        background-color: $primaryLightestColor;
        svg {
          color: $simplewhite;
        }
      }
    }

    .homeLink {
      height: 50px;
      width: 100%;
      img {
        height: 50px;
      }
    }

    &.topDrawerClosed {
      justify-content: center;
      .homeLink {
        display: none;
      }
    }

    &.topDrawerClosed {
      justify-content: center;
      .homeLink {
        display: none;
      }
    }
  }

  .avatar {
    margin: 50px $borderRadius $borderRadius $borderRadius;
    justify-content: center;
    width: $bigImageSize;
    height: $bigImageSize;
    @media only screen and (max-width: $breakpointMedium) {
      margin-top: 20px;
    }
    img {
      border-radius: 50%;
    }
    &.topDrawerClosed {
      width: $imageSize;
      height: $imageSize;
    }
  }
}

.homeLinkMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    height: 28px;
    img {
      height: 100%;
    }
  }
}

.mainContainer {
  transition: margin $animTime ease-out;
  [class~='MuiContainer-root'] {
    padding-left: 0;
    padding-right: $borderRadius;
  }
  margin-left: $sidebarWidth;

  @media only screen and (max-width: $breakpointMedium) {
    padding: 0;
    margin: 0;
    [class~='MuiContainer-root'] {
      padding: 0;
      margin: 0;
    }
  }
}

.mainContainerWhenClosed {
  @media only screen and (min-width: $breakpointMedium) {
    margin-left: calc($sidebarClosedWidth + 16px);
  }
}

.dashBody {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: calc(100vh - 120px - $borderRadius);
  padding: 20px;
  margin: 10px 10px 0px auto;
  background-color: $primaryLightestColor;
  background: $gradient;
  overflow-x: hidden;
  overflow-y: auto;

  transition: width $animTime ease-out;

  @media only screen and (max-width: $breakpointMedium) {
    margin: 0;
    background: $gradientMobile;
    height: calc(100vh - 130px);
    border-radius: 0;
  }
}

.setupBody {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: fit-content;
  padding: 20px;
  margin: 10px auto 0px 10px;
}

.modal {
  max-height: 80vh;
  margin-top: 10vh;
  padding: $borderRadius;
}

.dashboardCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 35px;
  border: 1px solid $simplewhite;
  border-radius: $borderRadius;

  [class~='MuiTypography-root'] {
    color: $simplewhite;
  }

  .dashboardCardIcon {
    height: 32px;
    width: 32px;
    color: $simplewhite;
  }
  .dashboardCardTitle[class~='MuiTypography-root'] {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .dashboardCardValue[class~='MuiTypography-root'] {
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
  }
}

.warningContainer {
  width: auto;
  height: 30px;
  background-color: orange;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: $borderRadius;
  padding: 10px 20px;
  margin: 10px 10px 0 0;
  a {
    color: white;
    font-weight: 600;
  }
  @media only screen and (max-width: $breakpointMedium) {
    border-radius: 0;
    margin: 0;
  }
}
