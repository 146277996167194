@use 'sass:color';
@import '../../common/styles/variables.module';
@import '../../common/styles/common.module.scss';

.portalContainer {
  background: #e6ecf1;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  .topBar {
    padding: 12px 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    @media only screen and (max-width: $breakpointMedium) {
      padding: 20px 25px;
      display: unset;
      height: 60px;
    }
  }
  .logo {
    height: 40px;
    width: 190px;
    img {
      height: 40px;
      width: 190px;
    }
  }
  .shopButton {
    cursor: pointer;
    width: 200px;
    height: 35px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4653e3;
    [class~='MuiTypography-root'] {
      color: white;
      font-weight: 500;
      font-size: 16px;
    }
    &:hover {
      filter: brightness(80%);
    }
    svg {
      color: white;
      margin-right: 10px;
    }
  }
  .banner {
    height: 300px;
    margin: 0 40px;
    background-image: url(https://storage.googleapis.com/onlypays-public/assets/images/background_banner_bills.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 16px;
    .title {
      width: 35%;
      margin-left: 110px;
      [class~='MuiTypography-root'] {
        font-weight: 500;
        font-size: 35px;
        color: white;
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
    .zafepay {
      border: 1px solid #36dabc;
      border-radius: 13px;
      font-weight: 500;
      font-size: 35px;
      color: #36dabc;
      padding: 3px 10px 8px;
    }
    .imageContainer {
      margin: auto;
      margin-right: 12%;
      margin-bottom: -4px;
      .manImage {
        height: 290px;
      }
    }
    @media only screen and (max-width: $breakpointMedium) {
      margin: 0 15px;
      background-image: url(https://storage.googleapis.com/onlypays-public/assets/images/background_banner_bills_mobile.png);
      height: 360px;
      position: relative;
      width: calc(100% - 30px);
      display: unset;
      .title {
        width: 65%;
        margin-left: 22px;
        margin-top: 30px;
        [class~='MuiTypography-root'] {
          font-weight: 400;
          font-size: 25px;
        }
      }
      .zafepay {
        font-weight: 400;
        font-size: 25px;
        padding: 2px 10px 6px;
      }
      .imageContainer {
        position: absolute;
        bottom: -4px;
        margin-bottom: 0;
        margin-right: 0;
        right: 0;
        .manImage {
          height: 200px;
        }
      }
    }
  }
  .billsContainer {
    padding: 60px 150px 50px;
    flex-grow: 1;
    @media only screen and (max-width: $breakpointMedium) {
      padding: 31px 15px;
    }
    .tabs {
      width: min-content;
      cursor: pointer;
      [class~='MuiTypography-root'] {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        color: #4653e3;
        border-bottom: 3px solid #4653e3;
        white-space: nowrap;
      }
      @media only screen and (max-width: $breakpointMedium) {
        [class~='MuiTypography-root'] {
          font-weight: 500;
          font-size: 24px;
          border-bottom: 2px solid #4653e3;
        }
      }
    }
    .optionsContainer {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: $breakpointMedium) {
        margin-top: 34px;
      }
      .row {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
        @media only screen and (max-width: $breakpointMedium) {
          display: unset;
          margin-top: 0;
        }
      }
      .optionButton {
        cursor: pointer;
        width: 264px;
        height: 74px;
        border-radius: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #4653e3;
        [class~='MuiTypography-root'] {
          color: white;
          font-weight: 500;
          font-size: 20px;
        }
        &:hover {
          background: #39bdaf;
        }
        svg {
          color: white;
          margin-right: 15px;
        }
        @media only screen and (max-width: $breakpointMedium) {
          margin: 0 60px 24px;
        }
      }
      .selected {
        background: #39bdaf;
      }
    }
    .formsContainer {
      margin: 20px 0 40px;
      background: white;
      padding: 25px 50px;
      border-radius: 16px;
      border: 2px solid #f0f0f0;
      @media only screen and (max-width: $breakpointMedium) {
        padding: 25px;
        margin-top: 0;
      }
      .title {
        [class~='MuiTypography-root'] {
          color: #1f1f1f;
          font-weight: 600;
          font-size: 24px;
        }
      }
      .form {
        padding: 30px 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .inputContainer {
          margin-bottom: 40px;
          width: 100%;
          [class~='MuiInputBase-root'] {
            border-radius: 11px;
            height: 42px;
          }
          [class~='MuiTypography-root'] {
            color: #1f1f1f;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 8px;
          }
        }
        .debt {
          margin-bottom: 40px;
          width: 100%;
          [class~='MuiTypography-root'] {
            color: #1f1f1f;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 8px;
          }
        }
        @media only screen and (max-width: $breakpointMedium) {
          padding: 20px 0;
        }
      }
    }
    .submitButton {
      cursor: pointer;
      width: 200px;
      height: 35px;
      border-radius: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #4653e3;
      [class~='MuiTypography-root'] {
        color: white;
        font-weight: 500;
        font-size: 16px;
      }
      &:hover {
        filter: brightness(80%);
      }
    }
    .whiteSpinnerContainer {
      margin: auto;
      [class~='MuiCircularProgress-root'] {
        color: white;
      }
    }
  }
  .footer {
    max-height: 250px;
    padding: 40px 150px 60px;
    background-color: #dae2e9;
    border: 1px solid #d4dde5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    align-self: flex-end;
    width: calc(100% - 302px);
    .leftColumn {
      [class~='MuiTypography-root'] {
        color: #4653e3;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 8px;
      }
      .logo {
        margin-top: 30px;
      }
    }
    .rightColumn {
      display: flex;
      flex-direction: row;
      gap: 13px;
      .socialIcon {
        border: 1px solid #4653e3;
        height: 45px;
        width: 45px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          margin-top: 3px;
        }
      }
    }
    @media only screen and (max-width: $breakpointMedium) {
      padding: 20px 26px;
      width: calc(100% - 54px);
      max-height: 205px;
      height: 205px;
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: flex-start;
      .leftColumn {
        margin-top: 18px;
      }
    }
  }
}
.popupContent {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  margin-top: 20px;
  .acceptButton {
    background-color: #4653e3;
    [class~='MuiTypography-root'] {
      color: white;
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 3px;
    }
  }
}
