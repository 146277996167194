@import '@fontsource/lato';
@import './variables.module';

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // background-color: $primaryDarkestColor;
  // background: linear-gradient(180deg, #0D3B56 0%, #002F4B 100%);
  color: $primaryDarkColor;
}

p[class~='MuiFormHelperText-root'] {
  text-align: right;
}

[class~='MuiOutlinedInput-root']:hover {
  [class~='MuiOutlinedInput-notchedOutline'] {
    border-color: $primaryDarkColor;
  }
}

[class~='MuiTypography-root'] {
  // color: $primaryColor;
  // color: inherit;
  &.primary {
    color: $primaryColor !important;
  }
  &.secondary {
    color: $secondaryColor !important;
  }
}

[class~='MuiButton-root'] {
  text-transform: none;
}

.loader[class~='MuiButton-root'][class~='Mui-disabled'] {
  display: flex;
  flex-direction: column;
  color: transparent;
  [class~='MuiCircularProgress-root'] {
    position: absolute;
    margin-right: 0;
  }
}

a {
  color: $primaryDarkColor;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.SnackbarContent-root.SnackbarItem-contentRoot {
  &.SnackbarItem-variantError {
    background-color: $simplewhite;
    border-radius: $borderRadius;
    color: $primaryDarkestColor;
    .MuiSvgIcon-root {
      color: $errorColor;
    }
  }
  &.SnackbarItem-variantInfo {
    background-color: $simplewhite;
    border-radius: $borderRadius;
    color: $primaryDarkestColor;
    .MuiSvgIcon-root {
      color: $primaryColor;
    }
  }
  &.SnackbarItem-variantSuccess {
    background-color: $simplewhite;
    border-radius: $borderRadius;
    color: $primaryDarkestColor;
    .MuiSvgIcon-root {
      color: $successColor;
    }
  }
  &.SnackbarItem-variantWarning {
    background-color: $simplewhite;
    border-radius: $borderRadius;
    color: $primaryDarkestColor;
    .MuiSvgIcon-root {
      color: $warningColor;
    }
  }

  .SnackbarItem-message {
    padding: 0;
  }

  .SnackbarItem-action .MuiSvgIcon-root {
    color: $lightygray;
  }

  .MuiSvgIcon-root {
    font-size: 43px !important;
  }
}
