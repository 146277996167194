@import '../../../common/styles/variables.module';

.paymentSummary {
  [class~='MuiAccordion-root'] {
    border-radius: 10px;
  }
  [class~='MuiAccordion-root']::before {
    height: 0px;
  }
  border: 1px solid #d5d8ec;
  border-radius: 10px;
  padding: 12px 12px 25px;
  @media only screen and (max-width: $breakpointMedium) {
    width: 80%;
  }
}

.sellerHeaderContent {
  margin: 0px;
  flex: auto;
  [class~='MuiTypography-h5'] {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  [class~='MuiTypography-h6'] {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  @media only screen and (max-width: $breakpointMedium) {
    [class~='MuiTypography-h5'] {
      font-size: 1.2rem;
    }
  }

  [class~='MuiPaper-root'] {
    box-shadow: none;
  }

  @media only screen and (min-width: $breakpointMedium) {
    display: flex;
    flex-direction: row;
  }
}

.desktopManyProducts {
  flex-direction: column;
  width: 100%;
}

.paperImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $primaryLightestColor;
  border-radius: 10px;
  margin-right: 40px;
  border: 1px solid #d5d8ec;

  @media only screen and (min-width: $breakpointMedium) {
    height: 180px;
    min-width: 180px;
  }

  @media only screen and (max-width: $breakpointMedium) {
    height: 200px;
    margin: 0;
    margin-bottom: 20px;
  }

  .imageShoppingCart {
    height: 90%;
    position: relative;
    bottom: -30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}

.paymentSummaryText {
  margin: auto 0;
  [class~='MuiTypography-h5'] {
    font-weight: 700;
    font-size: 20px;
  }
  @media only screen and (max-width: $breakpointMedium) {
    padding: 0 15px;
  }

  span:first-child {
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;

    @media only screen and (max-width: $breakpointMedium) {
      font-size: 20px;
      line-height: 35px;
      margin-right: 5px;

      @media only screen and (max-width: $breakpointMedium) {
        font-size: 30px;
      }
    }
  }
  .recurrence {
    font-size: 24px;
    font-weight: 600;
    @media only screen and (max-width: $breakpointMedium) {
      font-size: 16px;
    }
  }
  .description {
    color: #a9a9aa;
    @media only screen and (max-width: $breakpointMedium) {
      margin-top: 5px;
    }
  }
}

.tableContainer {
  background: white;
  border: 1px solid #d5d8ec;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
}

.accordion {
  [class~='MuiAccordionDetails-root'] {
    padding: 2px 5px;
  }
}

.gridHeader {
  margin: 15px 0;
  [class~='MuiTypography-root'] {
    margin: auto;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #1f1f1f;
  }
}
.gridBody {
  background-color: $lightergray;
  margin: 3px 8px 3px;
  border-radius: 10px;
  min-height: 34px;
  display: flex;
  align-items: center;
  [class~='MuiTypography-root'] {
    color: #1f1f1f;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
}

.priceContainer {
  box-shadow: 0px 0px 9px rgba(180, 180, 180, 0.25);
  border-radius: 20px;
  padding: 20px;
  margin-top: 30px;
  background: white;
}

.mainPriceGrid {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $lightGray;
}

.ticketing {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  margin-top: 10px;
  [class~='MuiButton-root'] {
    border-radius: 5px;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    padding: 5px 10px;
  }
  .lessButton {
    [class~='MuiButton-root'] {
      svg {
        color: #1f1f1f;
      }
      border: 1px solid $lightGray;
      border-radius: 0;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      min-width: 40px;
      max-width: 40px;
      min-height: 39px;
      border-right: 0;
      padding: 0px 10px;
      svg {
        max-height: 16px;
      }
    }
  }
  .ticketAmount {
    border: 1px solid $lightGray;
    border-left: 0;
    border-right: 0;
    padding: 0 10px;
    [class~='MuiTypography-root'] {
      margin: auto;
      font-size: 20px;
      font-weight: 600;
      line-height: 37px;
    }
  }
  .moreButton {
    [class~='MuiButton-root'] {
      svg {
        color: #1f1f1f;
      }
      border: 1px solid $lightGray;
      border-radius: 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      min-width: 40px;
      max-width: 40px;
      min-height: 39px;
      border-left: 0;
      padding-left: 10px;
      svg {
        max-height: 16px;
      }
    }
  }
}

.sellerPriceAmount {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  span:first-child {
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;

    @media only screen and (max-width: $breakpointMedium) {
      font-size: 20px;
      line-height: 35px;
      margin-right: 5px;

      @media only screen and (max-width: $breakpointMedium) {
        font-size: 30px;
      }
    }
  }

  @media only screen and (max-width: $breakpointMedium) {
    flex-direction: column;
    font-size: 20px;
    margin-top: 10px;
  }
}

.zpCommission {
  margin-top: 8px;
  [class~='MuiTypography-root'] {
    font-size: 15px;
    font-weight: 400;
  }
}

.integrationPriceTag {
  [class~='MuiTypography-root'] {
    font-size: 15px;
  }
  margin-top: 8px;
}

.integrationPrice {
  span:first-child {
    margin-top: 0px;
    font-size: 28px;
    line-height: 2rem;
  }
}

.price {
  display: flex;
  align-items: center;
  width: 100%;
  [class~='MuiFormControl-root'] {
    width: 100%;
    margin-left: 10px;
    [class~='MuiSelect-select'] {
      height: 0;
    }
  }
  .amount {
    .text {
      font-size: 24px;
      font-weight: 700;
    }
    .number {
      font-size: 30px;
      font-weight: 700;
    }
    margin-bottom: 0;
    @media only screen and (max-width: $breakpointMedium) {
      margin-bottom: 5px;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 12px;
  }
}

.customRecurrenceSelector {
  width: 100%;
  display: flex;
  align-items: center;
  [class~='MuiInputBase-input'] {
    padding: 8.5px 14px;
  }
  [class~='MuiTextField-root'] {
    width: 80%;
    margin-top: 5px;
  }
  [class~='MuiInputLabel-root'] {
    transform: translate(14px, 9px) scale(1);
  }
  [class~='MuiInputLabel-root'][class~='Mui-focused'] {
    label {
      border-color: #cacaca;
    }
    transform: translate(14px, -9px) scale(0.75);
  }
  [class~='MuiInputLabel-root'][class~='Mui-disabled'] {
    transform: translate(14px, -9px) scale(0.75);
  }
  [class~='MuiInputLabel-shrink'] {
    transform: translate(14px, -9px) scale(0.75);
    color: #666666;
  }
  [class~='MuiOutlinedInput-root'][class~='Mui-focused'] {
    border-color: #cacaca !important;
  }
}

.customizableRecurrence {
  max-width: 100%;
  display: flex;
  align-items: center;
  [class~='MuiTypography-root'] {
    white-space: nowrap;
    margin-right: 7px;
    margin-left: 7px;
  }
  [class~='MuiFormControl-root'] {
    [class~='MuiInputBase-input'] {
      padding: 8.5px 14px;
    }
  }
}

.paymentDays {
  display: flex;
  align-items: center;
  [class~='MuiButton-root'] {
    border-radius: 50%;
    padding: 6px;
    min-width: fit-content;
    margin-left: 4px;
  }
}

.divider {
  width: 110%;
  margin-left: -5%;
  margin-bottom: 8px;
}

.alternativeTotal {
  margin-top: 10px;
  span:first-child {
    font-size: 30px;
    font-weight: 700;
  }
  span:last-child {
    font-size: 20px;
  }
  [class~='MuiAlert-root'] {
    margin-top: 5px;
    padding: 0;
  }
  [class~='MuiAlert-message'] {
    padding: 0;
    color: #1f1f1f;
  }
}
