@use 'sass:color';
@import '../../../../common/styles/variables.module';

.horizontalContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 50px 205px 30px 63px;
  justify-content: center;
  gap: 85px;
  @media only screen and (max-width: 1330px) {
    flex-direction: column;
    align-items: center;
    padding: 50px 0 0 0;
    gap: 25px;
    margin-bottom: 17vh;
    width: 90%;
  }
}

.productDisplayContainer {
  width: 685px;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  @media only screen and (max-width: 1330px) {
    align-self: center;
    position: -webkit-relative;
    position: relative;
    max-width: 685px;
    width: 100%;
  }
  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
  }
}

.formContainer {
  width: 402px;
  @media only screen and (max-width: 1330px) {
    max-width: 402px;
    width: 100%;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 48px;

  .nextButton {
    width: 80%;
    margin: auto;
    box-shadow: none;
  }
}

.stickyBottom {
  position: -webkit-sticky;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 17vh;
  width: 100vw;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  .amountSummary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    max-width: 402px;
    border: 1px solid #9ea2be;
    background-color: #f5f6ff;
    border-radius: 10px;
    @media only screen and (max-width: 1330px) {
      min-width: 315px;
    }
    @media only screen and (max-width: $breakpointSmall) {
      min-width: 80%;
    }

    .quantity {
      min-width: 50%;
      [class~='MuiTypography-subtitle2'] {
        font-size: 12px;
      }
      [class~='MuiTypography-h4'] {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .total {
      width: 50%;
      [class~='MuiTypography-subtitle2'] {
        font-size: 12px;
      }
      [class~='MuiTypography-h4'] {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
