@import '../../common/styles/variables.module';

body {
  background: linear-gradient(180deg, #004a77 0%, #36b8c1 100%) !important;
}

// remove border-radius to paper
[class~='MuiContainer-root'] {
  padding: 0;
  margin: 0;
}

.baseContainer {
  background-image: url('https://storage.googleapis.com/onlypays-public/assets/images/BackgroundLoginDesktop.png') !important;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: $breakpointMedium) {
    flex-direction: column;
    background-image: url('https://storage.googleapis.com/onlypays-public/assets/images/BackgroundLoginMobile.png') !important;
  }
}

.logoLoginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 50vw;

  [class~='MuiTypography-h3'] {
    font-family: $fonts;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
  }

  [class~='MuiTypography-h5'] {
    font-family: $fonts;
    font-size: 29px;
    font-style: normal;
    color: #ffffff;
  }

  [class~='MuiGrid-container'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 60%;
  }

  .logoImage {
    width: 200px;
    height: auto;
  }

  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
    height: 45vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    [class~='MuiTypography-h3'] {
      font-size: 34px;
    }

    [class~='MuiTypography-h5'] {
      font-size: 20px;
    }

    [class~='MuiGrid-container'] {
      margin-bottom: 10%;
      margin-left: 10%;
    }
  }
}

.logoRegisterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 50vw;

  [class~='MuiTypography-h3'] {
    font-family: $fonts;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
  }

  [class~='MuiTypography-h5'] {
    font-family: $fonts;
    font-size: 29px;
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
  }

  [class~='MuiGrid-container'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
  }

  [class~='MuiGrid-item'] {
    padding: 0 5px;
  }

  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
    height: 40vh;

    [class~='MuiGrid-container'] {
      display: flex;
      flex-direction: row;
      width: auto;
    }

    [class~='MuiTypography-h5'] {
      font-size: 18px;
    }
  }
}

.boyImage {
  @media only screen and (max-width: $breakpointMedium) {
    max-width: 100px;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 50vw;
  a {
    text-decoration: underline;
  }

  // remove border-radius to paper
  [class~='MuiPaper-root'] {
    border-radius: 0;
  }

  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
    height: fit-content;
  }
}

.card {
  height: 100%;
  width: 100%;
  color: $simplegray;
  overflow: scroll;
  padding-left: 20%;

  [class~='MuiTypography-h3'] {
    font-family: $fonts;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    color: $primaryDarkerColor;
  }

  [class~='MuiTypography-h5'] {
    font-family: $fonts;
    font-size: 24px;
    font-style: normal;
    color: $primaryDarkerColor;
  }

  [class~='MuiTypography-h6'] {
    font-family: $fonts;
    font-style: normal;
    font-size: 24px;
    color: $primaryDarkerColor;
    font-weight: 700;
  }

  @media only screen and (max-width: $breakpointMedium) {
    height: fit-content;
    overflow: visible;
    padding-left: 10%;
    [class~='MuiTypography-h3'] {
      font-size: 28px;
    }

    [class~='MuiTypography-h5'] {
      font-size: 16px;
    }

    [class~='MuiTypography-h6'] {
      font-size: 18px;
      font-weight: 700;
    }
  }
}

// .logoImage {
//   width: $sidebarWidth;
//   max-height: 10vh;
//   margin: 0 auto;
//   position: absolute;
//   top: 30px;
//   @media only screen and (max-width: $breakpointMedium) {
//     top: 25px;
//     max-height: 5vh;
//   }
// }

.googleButton {
  background-color: transparent !important;
  color: $primaryDarkerColor !important;
  box-shadow: none !important;
  text-decoration: underline;
  margin: 6px;
  margin-top: 15px;
  width: auto;
  &:hover {
    border: 1px solid $primaryDarkerColor !important;
    border-radius: $borderRadius !important;
  }
  & > div {
    margin-left: 8px !important;
    margin-top: 2px !important;
    padding: 5px !important;
  }
  & > span {
    margin: auto !important;
    padding: 10px 10px 10px 0px !important;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 50px;
  width: 60%;

  [class~='MuiGrid-item'] {
    padding: 5px 0;
  }

  @media only screen and (max-width: $breakpointMedium) {
    margin: 20px;
    width: 80%;
  }
}

.formGridItem {
  width: 80%;

  [class~='MuiGrid-item'] {
    padding: 5px 0;
  }

  @media only screen and (max-width: $breakpointMedium) {
    width: 90%;
  }
}

.recoveryContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: url('https://storage.googleapis.com/onlypays-public/assets/images/BackgroundLoginDesktop.png') !important;
  position: relative;
  .recoveryBox {
    max-width: 490px;
    height: auto;
    padding: 40px 60px 60px;
    @media only screen and (max-width: $breakpointMedium) {
      padding: 20px 30px 30px;
      width: 70%;
      max-width: unset;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .logoImage {
    position: absolute;
    bottom: 50px;
    width: 200px;
    height: auto;
  }
  @media only screen and (max-width: $breakpointMedium) {
    padding: 0;
  }
}
