@import '../../../common/styles/variables.module.scss';
@import '../../../common/styles/common.module.scss';

.containerGrid {
  max-height: calc($containerHeight + 16px + 15px);
  gap: 16px;
}

.toButton {
  width: 100%;
  background: linear-gradient(180deg, #c43d2c 0%, #a63021 100%);
  border-radius: $borderRadius;
  margin: 0 auto 15px auto;
  min-height: 45px;
}

.firstInfoContainer {
  @extend .baseInfoContainer;
  [class~='MuiTypography-root'] {
    color: $pendingColor;
  }
  [class~='titleIcon2'] {
    color: $pendingColor;
  }
  [class~='footer'] {
    background-color: $pendingColor;
  }
}

.secondInfoContainer {
  @extend .baseInfoContainer;
  [class~='MuiTypography-root'] {
    color: $errorColor;
  }
  [class~='titleIcon2'] {
    color: $errorColor;
  }
  [class~='footer'] {
    background-color: $errorColor;
  }
}

.thirdInfoContainer {
  @extend .baseInfoContainer;
  [class~='MuiTypography-root'] {
    color: $successColor;
  }
  [class~='titleIcon2'] {
    color: $successColor;
  }
  [class~='footer'] {
    background-color: $successColor;
  }
}

.linkContainer {
  height: $containerHeight;
  margin-bottom: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: $borderRadius;
  background: $simplewhite;
  [class~='main'] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  [class~='mainImage'] {
    max-height: $containerImageMaxHeight;
    margin-top: 10px;
  }
  [class~='MuiButton-root'] {
    background-color: $warningColor;
    border-radius: $borderRadius;
    width: 75%;
    position: relative;
    bottom: calc($borderRadius);
  }
}

.start {
  margin: 20px;
  color: $simplewhite;
  .startTitle {
    margin-left: 15px;
    font-weight: bold;
  }
}

.productsGrid {
  margin: 20px 0px;
  padding: 20px;
  background-color: $simplewhite;
  border: 1px solid $slightyLightyGray;
  border-radius: 10px;

  [class~='MuiTypography-root'] {
    color: $primaryDarkColor;
  }

  .text {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .header {
    background-color: $slightyLightyGray;
    border-radius: 10px;
    padding: 10px 0px 10px 40px;
    [class~='MuiTypography-root'] {
      font-size: 16px;
      font-weight: 700;
    }
    @media only screen and (max-width: $breakpointMedium) {
      padding: 5px 0px 5px 10px;
      [class~='MuiTypography-root'] {
        text-align: center;
      }
    }
  }
  .row {
    background-color: $lightergray;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px 0px 10px 40px;
    [class~='MuiTypography-root'] {
      font-size: 14px;
    }
    @media only screen and (max-width: $breakpointMedium) {
      padding: 5px 0px 5px 10px;
      [class~='MuiTypography-root'] {
        text-align: center;
      }
    }
  }
}

.drawerContainer {
  .drawer {
    padding: 15px;
    min-width: 45vw;
    width: 45vw;
    .closePopup {
      display: flex;
      justify-content: flex-end;
      .xIcon {
        width: 15px;
        cursor: pointer;
      }
    }
    .inputField {
      width: 100%;
    }
    .notesInput {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      margin-bottom: 25px;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    min-width: 100vw;
    width: 100vw;
    z-index: 12000;
    .drawer {
      padding: 15px 8px;
      margin: 0;
      width: calc(100vw - 16px);
      .inputField {
        width: calc(100vw - 16px);
      }
    }
  }
}

.linkTo {
  cursor: alias;
  white-space: nowrap;
}
