@import '../styles/variables.module';

.pagination {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;

  .button {
    background-color: $primaryLightColor;
    border-radius: $borderRadius;
    svg {
      color: $simplewhite;
    }

    &[class~='Mui-disabled'] {
      background-color: $primaryLightestColor;
    }
    &:hover {
      background-color: #2881b8;
    }
  }
  .counter {
    display: flex;
    flex-direction: row;
    margin: auto 3px;
    [class~='MuiInputBase-input'] {
      padding: 5px 0 5px 10px;
      padding-right: 25px;
    }
    p {
      margin: auto;
      margin-left: 5px;
    }
    svg {
      margin-right: -5px;
    }
  }
}

.mobilePagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;

  .mobileButton {
    background-color: $yellowColor;
    border-radius: $borderRadius;
    width: 35px;
    height: 35px;
    svg {
      color: $simplewhite;
    }

    &[class~='Mui-disabled'] {
      background-color: $yellowLighterColor;
    }
  }
  .mobileCounter {
    display: flex;
    flex-direction: row;
    margin: auto 2px;
    [class~='MuiInputBase-input'] {
      padding: 5px 0 5px 10px;
      padding-right: 25px;
    }
    p {
      margin: auto;
      margin-left: 5px;
    }
    svg {
      margin-right: -5px;
    }
  }
}
