@use 'sass:color';
@import '../../common/styles/variables.module';

.sellerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $simplewhite;
  min-height: 100vh;
  @media only screen and (max-width: $breakpointMedium) {
    padding: 0 10px;
  }
}

.sellerHeaderAvatar {
  height: 100px;
  width: 100px;
  background: white;
  margin-bottom: 10px;
  @media only screen and (max-width: $breakpointMedium) {
    height: 150px;
    width: 150px;
    margin: 20px;
    margin-bottom: 0px;
  }
}

.sellerHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  .sellerHeaderContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.actionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  [class~='MuiButton-root'] {
    background: $primaryDarkerColor;
  }
}

.sellerFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  @media only screen and (max-width: $breakpointMedium) {
    margin: auto 20px;
  }
}

.loaderContainer {
  position: absolute;
  background: color.adjust($lightergray, $alpha: -0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: $borderRadius;
  z-index: 10000;

  img {
    width: 50vw;
    max-width: 500px;
    animation: pulse 0.666s infinite;
    animation-direction: alternate;
    animation-name: pulse;
  }

  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}

.topBar {
  position: absolute;
  height: 50px;
  background: $primaryDarkerColor;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  .opLogo {
    width: 160px;
    height: auto;
    margin: auto;
  }
  a {
    width: 160px;
  }
}