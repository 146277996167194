@import '../../../common/styles/variables.module.scss';
@import '../../../common/styles/common.module.scss';
.start {
  margin: 20px;
  color: $simplewhite;
  .startTitle {
    margin-left: 15px;
    font-weight: bold;
  }
  b {
    cursor: copy;
  }
}
.containerGrid {
  max-height: calc($containerHeight + 16px + 15px);
  gap: 16px;
}
.toButton {
  width: 100%;
  background: linear-gradient(180deg, #004a77 0%, #003657 100%);
  border-radius: $borderRadius;
  margin: 0 auto 15px auto;
  min-height: 45px;
}
.firstInfoContainer {
  @extend .baseInfoContainer;
  [class~='MuiTypography-root'] {
    color: $pendingColor;
  }
  [class~='titleIcon1'] {
    background: linear-gradient(180deg, #52a4cc 0%, rgba(82, 164, 204, 0.31) 100%);
  }
  [class~='footer'] {
    background-color: $pendingColor;
  }
}
.linkContainer {
  height: $containerHeight;
  margin-bottom: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: $borderRadius;
  background: linear-gradient(284.6deg, rgba(12, 58, 86, 0.76) 0%, #004a77 54.69%, #007f8b 100%);
  [class~='main'] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  [class~='mainImage'] {
    height: $containerImageMaxHeight;
    margin-top: 5px;
  }
  [class~='MuiButton-root'] {
    background-color: $primaryDarkerColor;
    border-radius: $borderRadius;
    width: 75%;
    position: relative;
    bottom: calc(15px + $borderRadius);
  }
}
.alternativeTable {
  @extend .mainDiv;
  [class~='mainBox'] {
    background-color: $simplewhite;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    color: $primaryDarkestColor;
    border: none;
    min-height: 76px;
    [class~='infoContainer'] {
      width: 100%;
      flex-direction: row;
      align-items: center;
      [class~='content'] {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        [class~='ellipsis'] {
          display: none;
        }
      }
    }
    [class~='moreInfo'] {
      background-color: $primaryColor;
      width: 23px;
    }
    [class~='moreInfo']:hover {
      cursor: pointer;
    }
  }
}
.nameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  .avatar {
    margin-right: $borderRadius;
  }
}

.rowsGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  .editableRow {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .wrapRow {
    display: flex;
    flex-direction: row;
    row-gap: 0;
    column-gap: 60px;
    flex-wrap: wrap;
    margin-top: 6px;
    [class~='MuiTypography-h6'] {
      margin-bottom: 0;
    }
    width: calc(100% - 20px);
  }
  .editIcon {
    width: 20px;
    margin: auto;
    cursor: pointer;
    align-self: flex-end;
  }
}

.drawerContainer {
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  .drawerField {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    [class~='MuiTextField-root'] {
      width: 100%;
    }
  }
  .drawerForm {
    width: 100%;
  }
  .actions {
    margin: auto;
    margin-top: 25px;
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: center;
  }
  [class~='MuiIconButton-root'] {
    color: $primaryColor;
  }

  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 60px;
    width: 100vw;
  }
}
