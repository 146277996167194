@import '../variables.module';

.paperNotification {
  transition: max-height $animTime;
  margin: 10px 10px 0px 0px;
  max-height: 0;
  max-width: 100%;
  overflow: hidden;

  [class~='MuiTypography-h5'] {
    color: $primaryDarkerColor;
    font-size: 20px;
  }

  [class~='MuiTypography-h6'] {
    color: $primaryDarkerColor;
    font-size: 17px;
  }

  @media only screen and (max-width: $breakpointMedium) {
    margin: 0;
    border-radius: 0;
  }
}

.paperNotificationClosed {
  margin-right: 10px;
}

.paperNotificationOpen {
  max-height: 300px;
  margin: 10px 10px 0px 0px;
  @media only screen and (max-width: $breakpointMedium) {
    margin: 0;
    border-radius: 0;
  }
}

.clientStyles {
  margin: 0 0 15px 0;
  padding-left: 30px;
  [class~='MuiTypography-root'] {
    color: #1f1f1f;
    font-size: 17px;
  }
  @media only screen and (max-width: $breakpointMedium) {
    padding-left: 10px;
    min-width: calc(100% + 10px);
    margin-left: -10px;
    margin-top: -20px;
  }
}

.removeNotification {
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.removeGrid {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.avatarGrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a {
  font-weight: 700;
  color: $primaryDarkerColor;
}

.divGridContainer {
  width: 100%;
  padding-top: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
}
