@import '../../../common/styles/variables.module.scss';
@import '../../../common/styles/common.module.scss';

.start {
  margin: 20px;
  color: $simplewhite;
  .startHeader {
    display: flex;
    align-items: center;
    .startTitle {
      margin-left: 15px;
      font-weight: bold;
    }
    svg {
      height: 34px;
    }
  }
}
.containerGrid {
  max-height: calc($containerHeight + 16px + 15px);
  gap: 16px;
}

.firstInfoContainer {
  @extend .baseInfoContainer;
  [class~='MuiTypography-root'] {
    color: $pendingColor;
  }
  [class~='titleIcon2'] {
    color: $pendingColor;
  }
  [class~='footer'] {
    background-color: $pendingColor;
  }
}

.secondInfoContainer {
  @extend .baseInfoContainer;
  [class~='MuiTypography-root'] {
    color: $successColor;
  }
  [class~='titleIcon2'] {
    color: $successColor;
  }
  [class~='footer'] {
    background-color: $successColor;
  }
}
