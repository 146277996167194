@import '../../../common/styles/variables.module.scss';
@import '../../../common/styles/common.module.scss';

.drawerContainer {
  .drawer {
    padding: 15px;
    min-width: 45vw;
    width: 45vw;
    .closePopup {
      display: flex;
      justify-content: flex-end;
      .xIcon {
        width: 15px;
        cursor: pointer;
      }
    }
    .inputField {
      width: 100%;
    }
    .notesInput {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      margin-bottom: 25px;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    min-width: 100vw;
    width: 100vw;
    z-index: 12000;
    .drawer {
      padding: 15px 8px;
      margin: 0;
      width: calc(100vw - 16px);
      .inputField {
        width: calc(100vw - 16px);
      }
    }
  }
}
