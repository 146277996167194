@use 'sass:color';
@import '../../styles/variables.module';

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 415px;
  .topContainer {
    display: flex;
    align-items: center;
  }
}

.queryBox {
  width: 300px;
  max-height: 41px;
  [class~='MuiOutlinedInput-notchedOutline'] {
    border: 1px solid $primaryDarkerColor;
    @media only screen and (max-width: $breakpointMedium) {
      margin-right: 20px;
    }
  }
  svg {
    color: $primaryDarkerColor;
    scale: 1.2;
  }
  input {
    margin-left: 10px;
    padding: 9px 0px;
  }
}

.openPopupButton {
  height: 41px;
  margin-left: 6px;
  border-color: $primaryDarkerColor;
  color: $primaryDarkerColor;
  svg {
    scale: 1;
    margin-right: 7px;
  }
  @media only screen and (max-width: $breakpointMedium) {
    display: none;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .title {
    font-size: 20px;
    font-weight: 700;
  }
  .xMark {
    height: 24px;
    width: 24px;
  }
  .xMark:hover {
    cursor: pointer;
  }
}

.sliderContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  gap: 25px;
  .slider {
    margin-top: 15px;
    [class~='MuiSlider-track'] {
      background-color: $secondaryColor;
      border: 1px solid $secondaryColor;
      height: 5px;
    }
    [class~='MuiSlider-rail'] {
      background-color: $lightygray;
      border: 1px solid $lightygray;
      height: 5px;
    }
    [class~='MuiSlider-mark'] {
      display: none;
    }
    [class~='MuiSlider-valueLabelOpen'] {
      background-color: transparent;
      color: $secondaryColor;
      font-weight: 600;
      padding: 0;
    }
    [class~='MuiSlider-thumb'] {
      background-color: $secondaryColor;
      padding: 0;
    }
  }
  .title {
    color: $primaryDarkerColor;
    font-weight: 600;
    margin-left: -10px;
  }
}

.statesContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0px;
  gap: 25px;
  .title {
    color: $primaryDarkerColor;
    font-weight: 600;
    margin-left: -10px;
  }
  .boxesContainer {
    display: flex;
    gap: 7px;
    flex-wrap: wrap;
    align-items: flex-start;
    .box {
      display: flex;
      align-items: center;
      border-radius: 10px;
      padding-right: 10px;
      [class~='MuiCheckbox-root'] {
        padding: 5px;
        svg {
          scale: 0.8;
        }
      }
    }
    .activatedBox {
      @extend .box;
      border: 1px solid $secondaryColor;
      color: $secondaryColor;
    }
    .deactivatedBox {
      @extend .box;
      border: 1px solid $lightygray;
      color: $lightygray;
    }
  }
}

.saveButton {
  background-color: $primaryDarkerColor;
  .text {
    font-weight: 500;
  }
}

.datesContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0px;
  gap: 25px;
  .title {
    color: $primaryDarkerColor;
    font-weight: 600;
    margin-left: -10px;
  }
  .dates {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    [class~='MuiTextField-root'] {
      min-width: 180px;
      margin-right: 15px;
      margin-bottom: 10px;
      input {
        padding: 10px 10px;
      }
    }
  }
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  .tag {
    color: #36b8c1;
    border: 1px solid #36b8c1;
    border-radius: $borderRadius;
    padding: 4px 7px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    [class~='MuiTypography-root'] {
      font-size: 14px;
    }
    .xMark {
      margin-left: 5px;
      margin-top: 3px;
      :hover {
        cursor: pointer;
      }
    }
  }
}
